import { GraphQLResult } from '@aws-amplify/api-graphql';
import { API, graphqlOperation } from 'aws-amplify';
import * as lodash from 'lodash';
import { List } from 'purify-ts/List';
import { Just, Maybe, Nothing } from 'purify-ts/Maybe';
import {
  listLinesAvailability,
  listProductsAvailability,
  listSailingsAvailability,
  quoteBooking,
} from '../../graphql/queries';
import { Language } from '../../Language.context';
import { sailingRequiresAccommodation, settings } from '../../settings';
import logger from '../../utils/logging';
import { createDefaultAccommodations, createDefaultAccommodationsForCruise } from '../catalog/accommodationActions';
import { CatalogContext } from '../catalog/catalogMachine';
import { APIResult, LoaderAction, LOADER_ACTION_EVENTS } from '../loader/loaderMachine';
import { SearchContext } from '../search/searchMachine';
import {
  AccommodationError,
  AccommodationInput,
  BookingQuote,
  BookingQuoteQuery,
  Catalog,
  Currency,
  DEFAULT_TARIFF,
  ErrorCode,
  ExtendedPassengerAndPet,
  ExtendedSailing,
  ExtendedVehicle,
  Form,
  isApiError,
  isPassengerInput,
  isPetInput,
  ListLinesAvailabilityQuery,
  ListProductsAvailabilityQuery,
  ListSailingsAvailabilityQuery,
  OnboardInput,
  Onboards,
  PriceError,
  Product,
  ProductError,
  Products,
  ProductType,
  QuoteBookingQuery,
  QuotesByTariff,
  SailingInput,
  SearchParams,
  SelectedSailing,
  Tariff,
  TariffInput,
  TariffPrice,
  Timetable,
  VehicleError,
} from '../types';
import { TripType } from '../types/search';
import {
  filterTimetablesByAvailability,
  filterTimetablesByErrorCode,
  findClosest,
  findFirstWithoutErrors,
  getCheapestSailing,
  getOfferCodeForCatalogs,
  getSailingErrors,
  getSelected,
  indexToLeg,
  isOfferCodeValid,
  sailingIsBefore,
  updateTimetable,
} from '../utils/sailingUtils';
import Cache, { SailingData } from './cache';
import { findCruisePairMaybe, isCruiseType, isDefaultOfferCode } from './cruise';
import { applyReturnTripDiscount } from './discounts';
import * as PricingService from './pricing';
import * as TimetableService from './timetable';
import {
  createAccommodationInputs,
  createOnboardInputs,
  createPassengerInputs,
  createPetInputs,
  createVehicleInputs,
  groupProducts,
} from './transform';
import { hasPets } from '../utils/passengerUtils';
import { hasEnoughPassengerCapacity, vehicleError } from '../utils/catalogUtils';

type CatalogAction = LoaderAction<CatalogContext, LOADER_ACTION_EVENTS>;

type InitialPriceQuery = {
  id: string;
  index: number;
  query: BookingQuoteQuery;
  error?: any;
};

type OfferCodeMeta = {
  sailingCode: string;
  offerCodeViable: {
    SPECIAL: boolean;
    STANDARD: boolean;
  };
};

export type PricingResult = {
  sailings: Timetable[];
  offerCodeMeta?: OfferCodeMeta[];
  fetchingPricesDone?: boolean;
  error: Maybe<Error>;
};

export type SailingPriceType = {
  index: number;
  sailingCode: string;
  prices: QuotesByTariff;
};

/**
 * A generic Starclub ID is used for price queries because we can pass any valid value there.
 * On the final booking step, we will use the actual ID given by the user.
 **/
export const GENERIC_STARCLUB_ID = '333123123123123';

export const GetSearchOptions = async ({ searchParams }: SearchContext) => {
  try {
    const graphqlQuery = {
      query: { currency: searchParams?.currency || Currency.EUR },
    };
    logger.info('-> listLinesAvailability:', graphqlQuery.query);

    const result = await API.graphql(graphqlOperation(listLinesAvailability, graphqlQuery));
    const { errors, data } = result as GraphQLResult<ListLinesAvailabilityQuery>;
    logger.info('<- listLinesAvailability:', data?.listLinesAvailability, 'errors:', errors);

    if (errors || !data || !data.listLinesAvailability || data.listLinesAvailability.some(isApiError)) {
      return Promise.reject('No available lines');
    } else {
      return data.listLinesAvailability;
    }
  } catch (err: any) {
    logger.error('GetSearchOptions failed:', err);
    if (err.errors?.length) {
      logger.error('Backend error:', err.errors[0].message);
    }
    return Promise.reject(err);
  }
};

export const GetCatalogWithParams = async ({ type, data }: CatalogAction) => {
  const { searchParams } = data.context;
  logger.debug('GetCatalogWithParams', type, searchParams);

  const promises = searchParams?.forms.map(async (form: Form) => {
    try {
      const graphqlQuery = {
        query: { ...form.params, currency: searchParams.currency },
      };
      logger.info('-> listSailingsAvailability:', graphqlQuery.query);

      const result = await API.graphql(graphqlOperation(listSailingsAvailability, graphqlQuery));
      const { data, errors } = result as GraphQLResult<ListSailingsAvailabilityQuery>;
      logger.info('<- listSailingsAvailability:', data?.listSailingsAvailability, 'errors:', errors);

      if (errors || !data || !data.listSailingsAvailability || data.listSailingsAvailability.some(isApiError)) {
        return {
          sailings: null,
          index: form.index,
          errors:
            data && data.listSailingsAvailability.some(isApiError)
              ? data?.listSailingsAvailability.filter((e) => isApiError(e))
              : errors
              ? errors
              : undefined,
        };
      } else {
        return {
          sailings: data.listSailingsAvailability,
          index: form.index,
        };
      }
    } catch (e) {
      logger.error('GetCatalogWithParams', e);
      return Promise.reject(e);
    }
  });

  return await Promise.all(promises)
    .then((result): APIResult => {
      return { type, result };
    })
    .catch((e) => {
      return { type, errors: e.errors };
    });
};

export const GetInitialProducts =
  ({ type, data }: CatalogAction) =>
  (callback: any) => {
    const { context } = data;

    // Clear cache so that default accommodations are chosen correctly.
    Cache.clear();

    const onDone = async () => {
      callback({ type: 'DONE', data: { type } });
    };

    return context.catalogs
      .reduce(
        (promise, catalog) =>
          promise.then(() =>
            getSelected(catalog).caseOf({
              Just: async (selected) => {
                const { products } = await fetchSailingData({
                  ...context.searchParams,
                  index: catalog.index,
                  passengers: context.passengers,
                  sailing: selected,
                });

                return callback({
                  type: 'EVENT',
                  data: {
                    type,
                    result: {
                      products,
                      sailingCode: selected.sailingCode,
                    },
                  },
                });
              },
              Nothing: async () => ({ index: catalog.index }),
            })
          ),
        Promise.resolve()
      )
      .then(onDone);
  };

const ProductQuery = async (
  currency: Currency,
  language: Language,
  sailing: ExtendedSailing
): Promise<{ products: Product[] }> => {
  try {
    const graphqlQuery = {
      query: {
        currency,
        language,
        departurePort: sailing.departurePort,
        departureDate: sailing.departureDate,
        departureTime: sailing.departureTime,
        arrivalPort: sailing.arrivalPort,
      },
    };
    logger.info('-> ProductQuery:', language, currency, sailing.sailingCode, sailing.shipName, graphqlQuery.query);

    const result = await API.graphql(graphqlOperation(listProductsAvailability, graphqlQuery));
    const { errors, data } = result as GraphQLResult<ListProductsAvailabilityQuery>;
    if (errors) logger.warn('<- ProductQuery FAIL:', errors, data);
    else
      logger.info(
        '<- ProductQuery OK:',
        language,
        currency,
        sailing.sailingCode,
        sailing.shipName,
        data?.listProductsAvailability
      );

    if (errors || !data || !data.listProductsAvailability || data.listProductsAvailability.some(isApiError)) {
      const error =
        (data &&
          data.listProductsAvailability &&
          List.find((error) => isApiError(error), data.listProductsAvailability)) ||
        ProductError.PRODUCT_QUERY_FAIL;
      return Promise.reject({ error });
    } else {
      const products = data.listProductsAvailability.reduce(
        (products, productOrError) =>
          isApiError(productOrError) ? products : products.concat(productOrError as Product),
        [] as Product[]
      );

      // It's possible that sailing has chair products available but no
      // available adult, junior or child passengers. In that case chair should
      // not be available for selection
      return {
        products: hasPaxPassengerCapacity(products) ? products : dropChairProduct(products),
      };
    }
  } catch (e: any) {
    logger.error('ProductQuery ERROR:', List.head(e.errors).orDefault(e));
    return Promise.reject({ error: e });
  }
};

const hasPaxPassengerCapacity = (products: Product[]): boolean => {
  return products.reduce((hasCapacity: boolean, p: Product) => {
    if (['A', 'J', 'C'].includes(p.code) && p.capacity.available && p.capacity.available > 0) {
      return true;
    }
    return hasCapacity;
  }, false);
};

const dropChairProduct = (products: Product[]): Product[] => {
  return products.filter((p) => p.code !== 'DS');
};

const updateProducts = async ({ id, index, result }: { id: string; index: number; result: QuotesByTariff }) => {
  //
  // Update product prices to cache.
  //
  return Cache.getAndUpdate(id, ({ products, ...rest }) => {
    if (typeof products === 'string') {
      return Promise.resolve({ products, ...rest });
    } else {
      const extractPrice = (quote: BookingQuote, productCode: string) =>
        List.find((_) => _.code === productCode, quote.onboards)
          .chain(({ legs }) => List.find((_) => _.leg === indexToLeg(index), legs))
          .map((_) => _.chargeInfo)
          .extract();

      const updatedProducts = Object.keys(products).reduce(
        (newProducts, type) => ({
          ...newProducts,
          [type as ProductType]:
            type in Onboards
              ? products[type as ProductType]?.map((product) => {
                  return {
                    ...product,
                    price: {
                      [Tariff.SPECIAL]:
                        extractPrice(result.SPECIAL, product.code) ||
                        (typeof product.price === 'object' ? product.price.SPECIAL : undefined),
                      [Tariff.STANDARD]:
                        extractPrice(result.STANDARD, product.code) ||
                        (typeof product.price === 'object' ? product.price.STANDARD : undefined),
                    },
                  };
                })
              : products[type as ProductType],
        }),
        {} as Products
      );

      return Promise.resolve({
        ...rest,
        products: updatedProducts,
        productPricesFetched: true,
      });
    }
  });
};

export const GetSelectedPricing = async ({ data, type }: CatalogAction) => {
  const query = createSelectedPriceObject(data.context, TariffInput.BOTH);
  const { type: tripType } = data.context.searchParams;

  try {
    const selected: { index: number; selected: SelectedSailing & ExtendedSailing }[] = Maybe.catMaybes(
      data.context.catalogs.map((catalog) =>
        getSelected(catalog).map((selected) => ({
          index: catalog.index,
          selected,
        }))
      )
    );

    const mandatoryAccommodationMissing = selected.reduce((accommodationMissing, { index, selected }) => {
      return (
        accommodationMissing ||
        (sailingRequiresAccommodation(selected, tripType, hasPets(data.context.passengers)) &&
          !query.accommodations.some((acc) => acc.legs.includes(indexToLeg(index))))
      );
    }, false);

    const sailingIssues = selected.reduce((issues, { selected, index }) => {
      let errorCode;

      const accommodationMissing =
        sailingRequiresAccommodation(selected, tripType, hasPets(data.context.passengers)) &&
        !query.accommodations.some((acc) => acc.legs.includes(indexToLeg(index)));

      const selectedVehicleError = vehicleError(data.context.vehicles, selected.products || {}, indexToLeg(index));

      if (!hasEnoughPassengerCapacity(selected.products, data.context.passengers)) {
        errorCode = ErrorCode.NO_PASSENGER_CAPACITY;
      } else if (selectedVehicleError) {
        errorCode = ErrorCode.NO_VEHICLE_SPACE
      } else if (accommodationMissing) {
        errorCode = hasPets(data.context.passengers)
          ? ErrorCode.NO_AVAIL_PET_CABINS
          : AccommodationError.NOT_AVAIL_ACCOMMODATIONS;
      }

      if (errorCode) {
        issues.push({
          sailingIndex: index,
          errorCode,
        });
      }
      return issues;
    }, [] as { sailingIndex: number; errorCode: ErrorCode | AccommodationError | undefined }[]);

    // sailings can be empty if none of the sailings has valid accommodation or vehicle space
    // available => no need to do a query
    if (query.sailings.length === 0 || (query.sailings.length !== selected.length && sailingIssues.length > 0)) {
      const firstIssue = sailingIssues[0];
      return {
        type,
        error: {
          errorCode: firstIssue.errorCode,
          sailingIndex: firstIssue.sailingIndex,
        },
      };
    }

    let result;
    if (!mandatoryAccommodationMissing) {
      result = await PriceQuery(query);
      result = await requeryIfNeeded(data.context.searchParams, query, result);

      if (isCruiseType(tripType)) {
        const specialOfferCode = result.SPECIAL?.sailings[0].offerCode;
        const standardOfferCode = result.STANDARD?.sailings[0].offerCode;

        if (!specialOfferCode && !standardOfferCode) {
          return {
            type,
            error: {
              errorCode: ErrorCode.NO_CRUISE_AVAILABLE,
            },
          };
        }
      }
    }

    return { type, result };
  } catch (error) {
    logger.warn('GetSelectedPricing', error);
    return { type, error };
  }
};

const requeryIfNeeded = async (
  searchParams: SearchParams,
  query: BookingQuoteQuery,
  result: QuotesByTariff
): Promise<QuotesByTariff> => {
  const { offerCode, type } = searchParams;
  if (isCruiseType(type)) {
    const specialOfferCode = result.SPECIAL?.sailings[0].offerCode;
    const standardOfferCode = result.STANDARD?.sailings[0].offerCode;

    if (!specialOfferCode && !standardOfferCode) {
      if (offerCode !== settings.defaultCruiseOfferCode) {
        const requery: BookingQuoteQuery = {
          ...query,
          sailings: query.sailings.map((sailing) => {
            return {
              ...sailing,
              offerCode: settings.defaultCruiseOfferCode,
            };
          }),
        };
        return await PriceQuery(requery);
      }
    }
  }
  return result;
};

export const GetTimetablePrices =
  ({ type, data }: { type: LOADER_ACTION_EVENTS; data: { context: CatalogContext } }) =>
  async (callback: any) => {
    // Tells the state machine to update available prices where received, but not advance to the next state.
    const onReceivedTimetablePrice = (result: PricingResult) =>
      callback({
        type: 'EVENT',
        data: {
          type,
          result,
        },
      });

    // Handle error by creating initial timetable data from the context
    const onError = (error: Error) => {
      logger.error('Error fetching sailings via timetable system', error);

      return TimetableService.createInitialTimetables(context.catalogs);
    };

    // Tells the state machine that all prices have been fetched and it's safe to advance to the next state.
    const onDone = (result: PricingResult) =>
      callback({
        type: 'DONE',
        data: {
          type,
          result,
        },
      });

    const { context } = data;
    const { catalogs, searchParams } = context;
    const timetables: Timetable[] = [];
    const offerCodeMeta = [] as OfferCodeMeta[];

    for (const catalog of catalogs) {
      try {
        const queryOptions = {
          forceAccommodation: searchParams.type === TripType.OVERNIGHT_CRUISE,
          removeOfferCode: false,
        };

        const timetablesWithoutPrices: Timetable[] = [];
        const timetableRetryList: Timetable[] = [];

        // Original timetable query
        const [timetablesWithPrices, timetablesWithError] = await fetchTimetablePrices(
          context,
          catalog,
          queryOptions,
          onError
        );
        timetablesWithPrices.forEach((timetable) => {
          offerCodeMeta.push({
            sailingCode: timetable.sailingCode,
            offerCodeViable: {
              SPECIAL: true,
              STANDARD: false,
            },
          });
        });

        const [timetablesWithOfferCodeError, timetablesWithOtherErrors] = filterTimetablesByErrorCode(
          timetablesWithError,
          [ErrorCode.OFFERCODE_NOT_VALID]
        );
        timetablesWithOfferCodeError.forEach((timetable) => {
          offerCodeMeta.push({
            sailingCode: timetable.sailingCode,
            offerCodeViable: {
              SPECIAL: false,
              STANDARD: false,
            },
          });
        });

        updateTimetable(timetableRetryList, timetablesWithOtherErrors);

        // 1st retry in case of invalid offer code
        // * for non-cruises: without offer code
        // * for cruises with default offer code (CRUISE) if something else was used as offer code
        if (
          timetablesWithOfferCodeError.length > 0 &&
          (!isCruiseType(searchParams.type) || !isDefaultOfferCode(searchParams.offerCode))
        ) {
          queryOptions.removeOfferCode = true;
          const [timetablesWithoutOfferCodeWithPrices, timetablesWithoutOfferCodeWithoutPrices] =
            await fetchTimetablePrices(context, catalog, queryOptions, onError);

          updateTimetable(timetablesWithPrices, timetablesWithoutOfferCodeWithPrices);
          updateTimetable(timetableRetryList, timetablesWithoutOfferCodeWithoutPrices);
        }

        const [timetablesWithIncorrectAccommodation, timetablesWithNonRetryableErrors] = filterTimetablesByErrorCode(
          timetableRetryList,
          [ErrorCode.ACCOMMODATION_INCORRECT]
        );

        updateTimetable(timetablesWithoutPrices, timetablesWithNonRetryableErrors);

        // Most common reason for missing prices is that timetable request was made without
        // accommodation so as fallback try to get prices with timetable query with accommodation
        // if accommodation wasn't already forced
        if (timetablesWithIncorrectAccommodation.length > 0 && !queryOptions.forceAccommodation) {
          queryOptions.forceAccommodation = true;
          const [timetablesWithAccommodationWithPrices, timetablesWithAccommodationWithoutPrices] =
            await fetchTimetablePrices(context, catalog, queryOptions, onError);

          updateTimetable(timetablesWithPrices, timetablesWithAccommodationWithPrices);
          // ignore errors on sailings that have received price in first timetable query
          updateTimetable(
            timetablesWithoutPrices,
            timetablesWithAccommodationWithoutPrices.filter(
              (tt) => !timetablesWithPrices.find((_) => _.sailingCode === tt.sailingCode)
            )
          );
        }

        console.warn(
          `timetablesWithoutPrices (cat-index: ${catalog.index}):`,
          timetablesWithoutPrices.length,
          timetablesWithoutPrices
        );

        const [blockingError, timetablesWithNonBlockingError] = filterTimetablesByErrorCode(timetablesWithoutPrices, [
          ErrorCode.PRODUCT_NOT_FOUND,
          ErrorCode.NO_VEHICLE_SPACE,
        ]);

        const fixedTimetables = await PricingService.fetchMissingPrices({
          ...context,
          sailings: timetablesWithNonBlockingError,
          callback: onReceivedTimetablePrice,
        });

        // Return trips can get a discount if the trip isn't a cruise
        const isEligibleForReturnTripDiscount = catalog.index > 0 && !isCruiseType(searchParams.type);

        const discountedTimetables = isEligibleForReturnTripDiscount
          ? applyReturnTripDiscount(timetablesWithPrices).concat(fixedTimetables)
          : timetablesWithPrices.concat(fixedTimetables);

        updateTimetable(timetables, discountedTimetables);
        updateTimetable(timetables, blockingError);
      } catch (e) {
        // Fall through to onDone
        logger.error(e);
      }
    }

    return onDone({
      error: Nothing,
      sailings: timetables,
      offerCodeMeta,
      fetchingPricesDone: true,
    });
  };

const fetchTimetablePrices = async (
  context: CatalogContext,
  catalog: Catalog,
  queryOptions: { forceAccommodation: boolean; removeOfferCode: boolean },
  onError: any
): Promise<[Timetable[], Timetable[]]> => {
  const retryQuery = await TimetableService.buildQueryWithOptions(context, catalog, queryOptions);
  logger.debug(`Fetching sailings with options ${JSON.stringify(queryOptions)} ...`, retryQuery);
  const timetableOrError = await TimetableService.fetchSailings(retryQuery);
  logger.debug('Sailings (timetable):', timetableOrError.extract());

  const sailings: Timetable[] = timetableOrError.caseOf({
    Left: onError,
    Right: (timetables) => timetables,
  });

  return filterTimetablesByAvailability(sailings);
};

const createInitialPricingObject = async ({
  catalogs,
  currency,
  index,
  language,
  offerCode,
  passengers,
  sailing,
  starclub,
  vehicles,
  type,
}: {
  catalogs: { index: number; selected?: SelectedSailing; sailings: ExtendedSailing[] }[];
  currency: Currency;
  index: number;
  language: Language;
  offerCode?: string;
  passengers: ExtendedPassengerAndPet[];
  sailing: ExtendedSailing;
  starclub: boolean;
  vehicles: ExtendedVehicle[];
  type: TripType;
}): Promise<InitialPriceQuery> => {
  const { defaultAccommodations, products } = await fetchSailingData({
    currency,
    index,
    language,
    passengers,
    sailing,
    type,
  });

  if (!sailing.vehicleError && Array.isArray(defaultAccommodations)) {
    const result: InitialPriceQuery = {
      index,
      id: sailing.sailingCode,
      query: {
        currency,
        language,
        agreementActive: starclub,
        sailings: [],
        passengers: createPassengerInputs(passengers.filter(isPassengerInput)),
        pets: createPetInputs(passengers.filter(isPetInput)),
        accommodations: [...createAccommodationInputs(defaultAccommodations, passengers)],
        vehicles: createVehicleInputs(vehicles),
        onboards: typeof products === 'string' ? [] : createInitialProducts(products, indexToLeg(index)),
      },
    };

    const actualOfferCode = getOfferCodeForCatalogs(type, offerCode, sailing.meta?.offercodeViable?.SPECIAL, catalogs);

    result.query.sailings.splice(
      index,
      0,
      createBookingSailingFromSailing(sailing, index, TariffInput.BOTH, actualOfferCode)
    );

    await catalogs
      .filter((_) => _.index !== index)
      .reduce(
        (promise, otherCatalog) =>
          promise.then(async () => {
            const proposedSailingCode = getSelected(otherCatalog)
              .map((_) => _.sailingCode)
              .extract();
            const previousCatalog = catalogs.find((c) => c.index === otherCatalog.index - 1);

            const direction = index > otherCatalog.index ? 1 : -1;
            const compareTo = previousCatalog?.sailings.find(
              (s) =>
                result.query.sailings[otherCatalog.index + direction].arrivalPortCode === s.arrivalPort &&
                result.query.sailings[otherCatalog.index + direction].departureDate === s.departureDate &&
                result.query.sailings[otherCatalog.index + direction].departureTime === s.departureTime &&
                result.query.sailings[otherCatalog.index + direction].departurePortCode === s.departurePort
            );
            const foundSelection = compareTo
              ? findClosest(otherCatalog.sailings, compareTo, proposedSailingCode)
                  .map((_) => _.sailingCode)
                  .extract()
              : proposedSailingCode;

            const selected = otherCatalog.sailings.find((_: ExtendedSailing) => _.sailingCode === foundSelection);

            const { defaultAccommodations } = await (selected
              ? fetchSailingData({
                  currency,
                  language,
                  passengers,
                  index: otherCatalog.index,
                  sailing: selected,
                  type,
                })
              : Promise.resolve({ defaultAccommodations: null }));

            if (!selected || !Array.isArray(defaultAccommodations) || selected.vehicleError) {
              result.query = {
                ...result.query,
                passengers: (result.query.passengers || []).map((o) => ({
                  ...o,
                  legs: o.legs?.filter((l) => l !== indexToLeg(otherCatalog.index)),
                })),
                pets: (result.query.pets || []).map((o) => ({
                  ...o,
                  legs: o.legs?.filter((l) => l !== indexToLeg(otherCatalog.index)),
                })),
                vehicles: (result.query.vehicles || []).map((vehicle) => ({
                  ...vehicle,
                  legs: vehicle.legs?.filter((leg) => leg !== indexToLeg(otherCatalog.index)),
                })),
              };
            } else {
              result.query.sailings.splice(
                otherCatalog.index,
                0,
                createBookingSailingFromSailing(selected, otherCatalog.index, TariffInput.BOTH, actualOfferCode)
              );

              if (defaultAccommodations && Array.isArray(defaultAccommodations)) {
                result.query.accommodations = [
                  ...(result.query.accommodations || []),
                  ...createAccommodationInputs(defaultAccommodations, passengers),
                ];
              }

              // Onboards are not populated (= selected) when loading initial prices.
            }
          }),
        Promise.resolve()
      );

    return result;
  }

  const errorObject: InitialPriceQuery = {
    index,
    id: sailing.sailingCode,
    query: {} as BookingQuoteQuery,
    error: defaultAccommodations, // Error code lies here
  };
  logger.debug('createInitialPricingObject, sailing:', sailing, 'defaultAccommodations:', defaultAccommodations);
  return errorObject;
};

export const createSelectedPriceObject = (
  { catalogs, passengers, searchParams, vehicles }: CatalogContext,
  tariff?: TariffInput
): BookingQuoteQuery => {
  const accommodations = [] as AccommodationInput[];
  const onboards = [] as OnboardInput[];
  const sailings = [] as SailingInput[];

  const selected = Maybe.catMaybes(
    catalogs.map((catalog) =>
      getSelected(catalog).map((selected) => ({
        index: catalog.index,
        selected,
      }))
    )
  );

  selected.forEach(({ index, selected }) => {
    const selectedVehicleError = vehicleError(vehicles, selected.products || {}, indexToLeg(index));
    if (
      (!selectedVehicleError && !selected.vehicleError && !((selected.price as TariffPrice)?.SPECIAL === PriceError.NO_VEHICLE_SPACE)) &&
      !isMandatoryAccommodationSoldOut(selected, searchParams.type, passengers) &&
      hasEnoughPassengerCapacity(selected.products, passengers)
    ) {
      const actualOfferCode = getOfferCodeForCatalogs(
        searchParams.type,
        searchParams.offerCode,
        selected.meta?.offercodeViable?.SPECIAL || selected.meta?.offercodeViable?.STANDARD,
        catalogs
      );

      sailings.splice(
        index,
        0,
        createBookingSailingFromSailing(selected, index, (tariff || selected.tariff) as TariffInput, actualOfferCode)
      );
      if (Array.isArray(selected.accommodations)) {
        accommodations.push(...createAccommodationInputs(selected.accommodations, passengers));
      }
      if (Array.isArray(selected.onboards)) {
        onboards.push(...createOnboardInputs(selected.onboards));
      }
    }
  });

  return {
    accommodations,
    onboards,
    sailings,
    agreementActive: searchParams.starclub,
    currency: searchParams.currency,
    language: searchParams.language,
    passengers: createPassengerInputs(passengers.filter(isPassengerInput)).map((p) => ({
      ...p,
      legs: p.legs.filter((l) => sailings.find(({ leg }) => leg === l)),
    })),
    pets: createPetInputs(
      passengers.filter(isPetInput).map((p) => ({
        ...p,
        legs: p.legs.filter((l) => sailings.find(({ leg }) => leg === l)),
      }))
    ),
    vehicles: createVehicleInputs(vehicles).map((p) => ({
      ...p,
      legs: p.legs.filter((l) => sailings.find(({ leg }) => leg === l)),
    })),
  };
};

const isMandatoryAccommodationSoldOut = (
  sailing: ExtendedSailing & SelectedSailing,
  tripType: TripType,
  passengersAndPets: ExtendedPassengerAndPet[]
): boolean => {
  // accommodation errors are strings, valid accommodation is represented as an array
  return (
    sailingRequiresAccommodation(sailing, tripType, hasPets(passengersAndPets)) &&
    (typeof sailing.accommodations === 'string' || !sailing.accommodations?.length)
  );
};

const createBookingSailingFromSailing = (
  sailing: ExtendedSailing,
  sailingIndex: number,
  tariff: TariffInput,
  offerCode?: string
): SailingInput => {
  return {
    leg: indexToLeg(sailingIndex),
    offerCode:
      isOfferCodeValid(sailing.meta, tariff) || offerCode === settings.defaultCruiseOfferCode ? offerCode : undefined,
    tariff,
    departureDate: sailing.departureDate,
    departureTime: sailing.departureTime,
    departurePortCode: sailing.departurePort,
    arrivalPortCode: sailing.arrivalPort,
  };
};

const createInitialProducts = (products: Products, leg: number): OnboardInput[] => {
  return Object.keys(products).reduce((result, key) => {
    if (key in Onboards && products[key as ProductType]) {
      const allInType: OnboardInput[] | undefined = products[key as ProductType]?.map((p) => ({
        type: p.type,
        code: p.code,
        amount: 1,
        legs: [leg],
      }));
      if (allInType) result.push(...allInType);
    }
    return result;
  }, [] as OnboardInput[]);
};

const PriceQuery = async (query: BookingQuoteQuery): Promise<QuotesByTariff> => {
  try {
    logger.info('-> PriceQuery:', query);

    const result = await API.graphql(graphqlOperation(quoteBooking, { query }));
    const { errors, data } = result as GraphQLResult<QuoteBookingQuery>;
    if (errors) logger.warn('<- PriceQuery FAIL:', errors, data);
    else logger.info('<- PriceQuery OK:', data?.quoteBooking);

    if (errors) {
      return Promise.reject(List.head(errors).extract());
    } else if (!data || !data.quoteBooking || data.quoteBooking.some(isApiError)) {
      return Promise.reject(List.head(data?.quoteBooking?.filter(isApiError) || []).extract());
    } else {
      const prices = PricingService.getPricesByTariff(data.quoteBooking as BookingQuote[]);
      // logger.debug('Resolve prices:', prices);
      return Promise.resolve(prices);
    }
  } catch (e: any) {
    logger.error('PriceQuery ERROR:', List.head(e.errors).orDefault(e));
    return Promise.reject(e);
  }
};

const createNewSelection = (
  maybePrevious: Maybe<ExtendedSailing & SelectedSailing>,
  newSelected: ExtendedSailing,
  { offerCode, type }: SearchParams,
  loading?: boolean
): {
  previous?: ExtendedSailing & SelectedSailing;
  selected: ExtendedSailing & SelectedSailing;
} => {
  let previous = maybePrevious.extract();
  if (previous) {
    previous = {
      ...previous,
      meta: {
        ...previous.meta,
        loading: loading !== undefined ? loading : true,
      },
    };
  }

  return {
    previous,
    selected: {
      ...newSelected,
      meta: {
        ...newSelected.meta,
        loading: true,
      },
      offerCode,
      products: ProductError.UNSPECIFIED, // Not loaded here
      tariff: maybePrevious.chainNullable((_) => _.tariff).orDefault(DEFAULT_TARIFF),
    },
  };
};

export const selectInitialSailings = async (
  { catalogs, searchParams }: CatalogContext,
  { type }: any
): Promise<{
  results: {
    index: number;
    previous?: ExtendedSailing & SelectedSailing;
    selected?: ExtendedSailing & SelectedSailing;
  }[];
  type?: string;
}> => {
  let outboundSelected: Maybe<ExtendedSailing>;
  const results = catalogs.map((catalog) => {
    const { previous, selected } = getSelected(catalog)
      .map((selected) => {
        let maybeNewSelection;

        if (!isCruiseType(searchParams.type) || !outboundSelected) {
          // RES-80 findClosest removed from here. Using findCheapest for return trip.
          maybeNewSelection = findFirstWithoutErrors(catalog.sailings, selected.sailingCode)
            .map((closest) =>
              [closest].concat(
                catalog.sailings.filter(
                  (sailing) =>
                    closest.sailingCode !== sailing.sailingCode &&
                    getSailingErrors(sailing).length <= 0 &&
                    closest.departureDate === sailing.departureDate
                )
              )
            )
            .chainNullable(getCheapestSailing);
          if (catalog.index === 0) {
            outboundSelected = maybeNewSelection;
          }
        } else {
          const findCruiseParams = outboundSelected.caseOf({
            Nothing: () => ({} as any),
            Just: (sailing) => ({
              departurePort: sailing.departurePort,
              shipName: sailing.shipName,
              selectedTimes: {
                arrivalDate: sailing.arrivalDate,
                arrivalTime: sailing.arrivalTime,
                departureDate: sailing.departureDate,
                departureTime: sailing.departureTime,
              },
            }),
          });

          maybeNewSelection = findCruiseParams.selectedTimes
            ? findCruisePairMaybe(
                findCruiseParams.departurePort,
                findCruiseParams.shipName,
                findCruiseParams.selectedTimes,
                catalog.sailings,
                searchParams.type
              )
            : (Maybe.fromNullable() as Maybe<ExtendedSailing>);
        }

        return maybeNewSelection.caseOf({
          Just: (newSelected) => createNewSelection(Maybe.of(selected), newSelected, searchParams),
          Nothing: () => ({ previous: undefined, selected }),
        });
      })
      .orDefault({} as any);

    return { previous, selected, index: catalog.index };
  });

  return { results, type };
};

export const selectSailing = async (
  { catalogs, searchParams }: CatalogContext,
  { data }: any
): Promise<{
  results: {
    index: number;
    previous?: ExtendedSailing & SelectedSailing;
    selected?: ExtendedSailing & SelectedSailing;
  }[];
}> => {
  const { index, sailingCode, loading } = data as { index: number; sailingCode: string; loading?: boolean };
  let outboundSelected: Maybe<ExtendedSailing>;

  const results = catalogs.reduce(
    (results, catalog) => {
      const oldSelected = getSelected(catalog);

      const changeSelected = (
        newSelected: ExtendedSailing
      ): {
        index: number;
        previous?: ExtendedSailing & SelectedSailing;
        selected?: ExtendedSailing & SelectedSailing;
      } => {
        const { previous, selected } = createNewSelection(oldSelected, newSelected, searchParams, loading);
        return {
          previous,
          selected,
          index: catalog.index,
        };
      };

      if (catalog.index === index) {
        const selected = List.find((_) => _.index === index, catalogs).chain((catalog) =>
          List.find((_) => _.sailingCode === sailingCode, catalog.sailings)
        );

        if (index === 0) {
          outboundSelected = selected;
        }

        const result = selected.caseOf({
          Just: changeSelected,
          Nothing: () => ({ index: catalog.index }), // <-- may cause crashes later on
        });

        return results.concat(result);
      } else if (catalog.index > index) {
        let selected;
        if (isCruiseType(searchParams.type) && catalog.index > 0 && outboundSelected) {
          const findCruiseParams = outboundSelected.caseOf({
            Nothing: () => ({} as any),
            Just: (sailing) => ({
              departurePort: sailing.departurePort,
              shipName: sailing.shipName,
              selectedTimes: {
                arrivalDate: sailing.arrivalDate,
                arrivalTime: sailing.arrivalTime,
                departureDate: sailing.departureDate,
                departureTime: sailing.departureTime,
              },
            }),
          });

          selected = findCruiseParams.selectedTimes
            ? findCruisePairMaybe(
                findCruiseParams.departurePort,
                findCruiseParams.shipName,
                findCruiseParams.selectedTimes,
                catalog.sailings,
                searchParams.type
              )
            : (Maybe.fromNullable() as Maybe<ExtendedSailing>);
        } else {
          const outboundCatalog = List.find((_) => _.index === catalog.index - 1, results);
          const previousSelected = outboundCatalog.chainNullable(({ selected }) => selected);
          selected = previousSelected
            .chain((previousSelected) =>
              oldSelected.chain((oldSelected) =>
                // When old selection and previous selection are available,
                // check if the old selection in this catalog is before
                // the updated selection in previous catalog.
                sailingIsBefore(oldSelected, previousSelected)
                  ? findClosest(catalog.sailings, previousSelected)
                  : Just(oldSelected)
              )
            )
            .alt(oldSelected);
        }
        const result = selected.caseOf({
          Just: changeSelected,
          Nothing: () => ({ index: catalog.index }),
        });

        return results.concat(result);
      }

      return results.concat({ index: catalog.index, selected: oldSelected.extract() });
    },
    [] as {
      index: number;
      previous?: ExtendedSailing & SelectedSailing;
      selected?: ExtendedSailing & SelectedSailing;
    }[]
  );

  return {
    results,
  };
};

export const updateSelectedAccommodation = async (
  { passengers, searchParams }: CatalogContext,
  { data }: any
): Promise<{
  results: { index: number; selected?: ExtendedSailing & SelectedSailing }[];
  type?: string;
}> => {
  const { results, type } = data as {
    results: {
      index: number;
      previous?: ExtendedSailing & SelectedSailing;
      selected?: ExtendedSailing & SelectedSailing;
    }[];
    type?: string;
  };

  console.log('updateSelectedAccommodation', type, results);
  const augmentedResults = await Promise.all(
    results.map(
      ({
        index,
        selected,
      }: {
        index: number;
        selected?: ExtendedSailing & SelectedSailing;
      }): Promise<{
        index: number;
        selected?: ExtendedSailing & SelectedSailing;
      }> => {
        return Maybe.fromNullable(selected).caseOf({
          Just: async (selected) => {
            const sailingData = await fetchSailingData({
              ...searchParams,
              index,
              passengers,
              sailing: selected,
            });

            return {
              index,
              selected: {
                ...selected,
                ...sailingData,
                accommodations: sailingData.defaultAccommodations, // use defaults for now
                meta: {
                  ...selected.meta,
                  initialAccommodations:
                    (Array.isArray(sailingData.defaultAccommodations) && sailingData.defaultAccommodations.length) || 0,
                },
              },
            };
          },
          Nothing: async () => ({ index }),
        });
      }
    )
  );

  if (searchParams.type === TripType.OVERNIGHT_CRUISE) {
    const outboundProducts = augmentedResults[0].selected?.products || ({} as Products);
    const inboundProducts = augmentedResults[1].selected?.products || ({} as Products);

    // update cruise accommodation based on products available on both sailings
    const cruiseAccommodations = await Promise.all(
      augmentedResults.map(
        ({
          index,
          selected,
        }: {
          index: number;
          selected?: ExtendedSailing & SelectedSailing;
        }): Promise<{
          index: number;
          selected?: ExtendedSailing & SelectedSailing;
        }> => {
          return Maybe.fromNullable(selected).caseOf({
            Just: async (selected) => {
              const defaultAccommodations = createDefaultAccommodationsForCruise(
                selected,
                passengers,
                outboundProducts,
                inboundProducts,
                index,
                searchParams.type
              );

              return {
                index,
                selected: {
                  ...selected,
                  accommodations: defaultAccommodations, // use defaults for now
                  meta: {
                    ...selected.meta,
                    initialAccommodations: (Array.isArray(defaultAccommodations) && defaultAccommodations.length) || 0,
                  },
                },
              };
            },
            Nothing: async () => ({ index }),
          });
        }
      )
    );

    return {
      type,
      results: cruiseAccommodations,
    };
  } else {
    return {
      type,
      results: augmentedResults,
    };
  }
};

export const fetchSailingData = ({
  currency,
  index,
  language,
  passengers,
  sailing,
  type,
}: {
  currency: Currency;
  index: number;
  language: Language;
  passengers: ExtendedPassengerAndPet[];
  sailing: ExtendedSailing;
  type: TripType;
}): Promise<SailingData> => {
  const { sailingCode } = sailing;
  logger.debug('fetchSailingData:', language, currency, sailingCode, index, passengers, sailing);
  return Cache.getAndUpdate(sailingCode, async (sailingData) => {
    if (sailingData && typeof sailingData.products !== 'string' && Object.keys(sailingData.products).length > 0) {
      // Make a deep copy, in order to avoid nasty mutating later on
      return lodash.cloneDeep(sailingData);
    } else {
      return ProductQuery(currency, language, sailing)
        .then((result) => groupProducts(result))
        .catch(() => ProductError.PRODUCT_QUERY_FAIL)
        .then((productsOrError) => {
          const defaultAccommodations =
            typeof productsOrError === 'string'
              ? undefined
              : createDefaultAccommodations(sailing, passengers, productsOrError, index, type);

          return { ...sailingData, defaultAccommodations, products: productsOrError };
        });
    }
  });
};

export const GetProductPrices =
  ({ type, data }: CatalogAction) =>
  (callback: any) => {
    const { context } = data;
    const { catalogs, passengers, searchParams, vehicles } = context;

    const onReceivedProductPrice = (result: {
      index: number;
      products: Products | ProductError;
      sailingCode: string;
    }) =>
      callback({
        type: 'EVENT',
        data: {
          type,
          result,
        },
      });

    const onDone = () =>
      callback({
        type: 'DONE',
        data: {
          type,
        },
      });

    Promise.all(
      catalogs.map((catalog) =>
        getSelected(catalog).caseOf({
          Just: async (selected) => {
            const { productPricesFetched } = await fetchSailingData({
              ...searchParams,
              passengers,
              index: catalog.index,
              sailing: selected,
            });

            if (!productPricesFetched) {
              const query = await createInitialPricingObject({
                ...searchParams,
                catalogs,
                passengers,
                vehicles,
                index: catalog.index,
                sailing: selected,
              });

              // Check if we have valid query and no errors
              if (query.query && Object.keys(query.query).length > 0 && !query.error) {
                try {
                  const result = await PriceQuery(query.query);
                  const { products } = await updateProducts({ ...query, result });
                  onReceivedProductPrice({
                    products,
                    index: catalog.index,
                    sailingCode: selected.sailingCode,
                  });
                } catch (error) {
                  logger.error('API query error:', error);
                }
              }
            }
          },
          Nothing: async () => {
            logger.error('No selected sailing in catalog', catalogs);
          },
        })
      )
    ).then(onDone);
  };

export const GetPricesForSailing =
  ({ type, data }: CatalogAction) =>
  (callback: any) => {
    logger.debug('GetPricesForSailing:', type, data.context);

    const { catalogs, searchParams, passengers, vehicles } = data.context;
    const { index, sailingCodes } = data.event.data;

    const onReceivedSailingPrice = (result: any) =>
      callback({
        type: 'EVENT',
        data: {
          type,
          result,
        },
      });

    const onDone = () =>
      callback({
        type: 'DONE',
        data: {
          type,
        },
      });

    Promise.all(
      sailingCodes.map(async (sailingCode: string) => {
        const sailing = catalogs[index].sailings.find((s) => s.sailingCode === sailingCode);
        if (sailing) {
          try {
            const result = await doGetPricesForSailing(index, catalogs, sailing, searchParams, passengers, vehicles);
            logger.debug('GetPricesForSailing, price result:', type, result);
            onReceivedSailingPrice(result);
          } catch (error) {
            logger.warn('Error when trying to fetch prices for sailing:', error);
            onReceivedSailingPrice(error);
          }
        } else {
          logger.debug('No sailing found from catalog with code', sailingCode);
          onReceivedSailingPrice({ index, sailingCode, prices: PriceError.NO_PRICE });
        }
      })
    ).then(onDone);
  };

const doGetPricesForSailing = async (
  catalogIndex: number,
  catalogs: Catalog[],
  sailing: ExtendedSailing,
  searchParams: SearchParams,
  passengers: ExtendedPassengerAndPet[],
  vehicles: ExtendedVehicle[]
): Promise<SailingPriceType> => {
  logger.debug('doGetPricesForSailing:', sailing.sailingCode, sailing);

  const initialPricingObject: InitialPriceQuery = await createInitialPricingObject({
    ...searchParams,
    catalogs,
    passengers,
    vehicles,
    index: catalogIndex,
    sailing,
  });
  logger.debug('doGetPricesForSailing, initialPricingObject:', initialPricingObject);

  if (initialPricingObject.error) {
    logger.warn('Error when creating initialPricingObject for sailing', sailing.sailingCode, initialPricingObject);
    const prices = initialPricingObject?.error || PriceError.NO_PRICE;
    return Promise.reject({ index: catalogIndex, sailingCode: sailing.sailingCode, prices });
  }

  try {
    // TODO Check if Onboards are really needed for this query
    const priceResult = await PriceQuery(initialPricingObject.query);
    logger.debug('doGetPricesForSailing, priceResult:', priceResult);
    return Promise.resolve({
      index: catalogIndex,
      sailingCode: sailing.sailingCode,
      prices: priceResult,
    });
  } catch (error) {
    logger.error('doGetPricesForSailing, API query error:', error);
    return Promise.reject({
      index: catalogIndex,
      sailingCode: sailing.sailingCode,
      prices: PriceError.NO_PRICE,
    });
  }
};
