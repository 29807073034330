import { parseISO } from 'date-fns';
import { List } from 'purify-ts/List';
import { Just, Maybe, Nothing } from 'purify-ts/Maybe';
import { isDayCruiseTime, isDaySailing, settings } from '../../settings';
import logger from '../../utils/logging';
import * as API from '../types';
import {
  Catalog,
  ErrorCode,
  ExtendedSailing,
  LegMeta,
  Sailing,
  TariffInput,
  TariffPrice,
  Timetable,
  TripType,
} from '../types';
import { isAfter, isBefore, parse } from 'date-fns/fp';
import { getOfferCodeForCruise, isCruiseType } from '../api/cruise';
import { isReturnForceMealOfferCode } from './productUtils';

const encodePortPair = (ports: API.Port[]): string => ports.join('-');

const portDepartureMap: Map<string, number> = new Map([
  [encodePortPair([API.Port.FIHEL, API.Port.DETRV]), 1],
  [encodePortPair([API.Port.DETRV, API.Port.FIHEL]), 1],
  [encodePortPair([API.Port.FINLI, API.Port.SEKPS]), 2],
  [encodePortPair([API.Port.SEKPS, API.Port.FINLI]), 2],
  [encodePortPair([API.Port.FINLI, API.Port.FILAN]), 2],
  [encodePortPair([API.Port.FILAN, API.Port.FINLI]), 2],
  [encodePortPair([API.Port.SEKPS, API.Port.FILAN]), 2],
  [encodePortPair([API.Port.FILAN, API.Port.SEKPS]), 2],
  [encodePortPair([API.Port.SEMMA, API.Port.PLSWI]), 2],
  [encodePortPair([API.Port.PLSWI, API.Port.SEMMA]), 2],
  [encodePortPair([API.Port.SEMMA, API.Port.DETRV]), 3],
  [encodePortPair([API.Port.DETRV, API.Port.SEMMA]), 3],
]);

type SelectedType = API.ExtendedSailing & API.SelectedSailing;
type SelectedTypeMaybe = Maybe<SelectedType>;
type SelectedSailingType = { index: number; sailing: SelectedType };

export const getSelected = ({
  selected,
  sailings,
}: {
  selected?: API.SelectedSailing;
  sailings: API.ExtendedSailing[];
}): SelectedTypeMaybe =>
  Maybe.fromNullable(selected).chain((selected: API.SelectedSailing) =>
    List.find((_) => _.sailingCode === selected.sailingCode, sailings).map((sailing: API.ExtendedSailing) => ({
      ...sailing,
      ...selected,
    }))
  ) as SelectedTypeMaybe;

export const collectSelectedSailings = <T>(
  catalogs: API.Catalog[],
  onSelected: (index: number, selected: SelectedType) => T | T[]
): T[] => {
  return catalogs.reduce(
    (collected, catalog) =>
      getSelected(catalog)
        .map((selected: SelectedType) => collected.concat(onSelected(catalog.index, selected)))
        .orDefault(collected),
    [] as T[]
  );
};

export const getSelectedSailings = (catalogs: API.Catalog[]): SelectedSailingType[] =>
  catalogs.reduce(
    (sailings, catalog, index) =>
      getSelected(catalog).mapOrDefault(
        (sailing: API.SelectedSailing) => [
          ...sailings,
          {
            index,
            sailing,
          },
        ],
        sailings
      ) as SelectedSailingType[],
    [] as SelectedSailingType[]
  );

export const onCatalogSelectedSailings = (
  catalogs: API.Catalog[],
  callback: (catalog: API.Catalog, selected: SelectedType) => API.Catalog
): API.Catalog[] => {
  return catalogs.map((catalog) =>
    getSelected(catalog)
      .map((selected: SelectedType) => callback(catalog, selected))
      .orDefault(catalog)
  );
};

export const onCatalogSelectedSailing = (
  catalogs: API.Catalog[],
  { leg }: { leg: number },
  callback: (catalog: API.Catalog, selected: SelectedType) => API.Catalog
): API.Catalog[] => {
  return catalogs.map((catalog) => {
    return legToIndex(leg) === catalog.index
      ? getSelected(catalog)
          .map((selected: SelectedType) => callback(catalog, selected))
          .orDefault(catalog)
      : catalog;
  });
};

export const onCatalogSomeSelectedSailings = (
  catalogs: API.Catalog[],
  legs: number[],
  callback: (catalog: API.Catalog, selected: SelectedType) => API.Catalog
): API.Catalog[] => {
  return catalogs.map((catalog) => {
    return legs.map((leg) => legToIndex(leg)).includes(catalog.index)
      ? getSelected(catalog)
          .map((selected: SelectedType) => callback(catalog, selected))
          .orDefault(catalog)
      : catalog;
  });
};

export const getCheapestChargeInfo = (sailings: API.ExtendedSailing[]): API.ChargeInfo | undefined => {
  const cheapestForEachSailing = Maybe.catMaybes(
    sailings.map((sailing: ExtendedSailing): Maybe<API.ChargeInfo> => {
      // Lookup first tariff prices for sailing
      const tariffPricesForSailing = API.maybeTariffPrice(sailing).chainNullable((tariffPrice) => {
        const cheaper = pickCheaperTariff(tariffPrice);
        // console.log('getCheapestChargeInfo, tariffPrice', tariffPrice, 'cheaper', cheaper)
        return cheaper;
      });

      // TODO The cheaper price may be in initialTariffPricesForSailing
      if (tariffPricesForSailing.isJust()) {
        return tariffPricesForSailing;
      }

      // Lookup initial tariff prices for sailing (from meta)
      const initialTariffPricesForSailing = API.maybeTariffInitialPrice(sailing).chainNullable((tariffPrice) => {
        const cheaper = pickCheaperTariff(tariffPrice);
        // console.log('getCheapestChargeInfo, initialTariffPricesForSailing', tariffPrice, 'cheaper', cheaper)
        return cheaper;
      });
      return initialTariffPricesForSailing;
    })
  );

  // Find cheapest charge info for each sailing if one exists.
  return cheapestForEachSailing.length <= 0
    ? undefined
    : cheapestForEachSailing.reduce((prev, curr) => (prev.charge > curr.charge ? curr : prev));
};

export const getCheapestSailing = (sailings: API.ExtendedSailing[]): API.ExtendedSailing | undefined => {
  if (sailings.length === 1) return sailings[0]; // return first one if it is alone (regardless of any error there)
  return sailings.reduce((result, sailing) => {
    if (getSailingErrors(sailing).length > 0) return result;
    if (!result) return sailing;

    const oldSailingPrice = pickCheaperPrice(result);
    const newSailingPrice = pickCheaperPrice(sailing);

    return oldSailingPrice.mapOrDefault(
      ({ charge: oldCharge }) =>
        newSailingPrice.mapOrDefault(({ charge: newCharge }) => (oldCharge > newCharge ? sailing : result), result),
      result ? result : sailing // Keep first result / sailing, if no prices available. (oldSailingPrice === undefined)
    );
  }, undefined as API.ExtendedSailing | undefined);
};

export const createTariffPrice = (
  existing: API.TariffPrice | string | null | undefined,
  tariff: API.Tariff,
  chargeInfo?: API.ChargeInfo
): API.TariffPrice | undefined => {
  return chargeInfo && existing && typeof existing !== 'string'
    ? { ...existing, [tariff]: chargeInfo }
    : chargeInfo && tariff === API.Tariff.STANDARD
    ? {
        [API.Tariff.STANDARD]: chargeInfo,
        [API.Tariff.SPECIAL]: null,
      }
    : chargeInfo && tariff === API.Tariff.SPECIAL
    ? {
        [API.Tariff.STANDARD]: null,
        [API.Tariff.SPECIAL]: chargeInfo,
      }
    : undefined;
};

export const pickCheaperPrice = (sailing: API.ExtendedSailing): Maybe<API.ChargeInfo> =>
  API.maybeTariffPrice(sailing).chainNullable(pickCheaperTariff);

const pickCheaperTariff = (tariffPrice: API.TariffPrice): API.ChargeInfo | undefined => {
  if (tariffPrice.SPECIAL && tariffPrice.STANDARD) {
    return typeof tariffPrice.SPECIAL !== 'string' && typeof tariffPrice.STANDARD !== 'string'
      ? tariffPrice.SPECIAL.charge > tariffPrice.STANDARD.charge
        ? tariffPrice.STANDARD
        : tariffPrice.SPECIAL
      : typeof tariffPrice.SPECIAL !== 'string'
      ? tariffPrice.SPECIAL
      : typeof tariffPrice.STANDARD !== 'string'
      ? tariffPrice.STANDARD
      : undefined;
  } else {
    return typeof tariffPrice.SPECIAL !== 'string' && tariffPrice.SPECIAL
      ? tariffPrice.SPECIAL
      : typeof tariffPrice.STANDARD !== 'string' && tariffPrice.STANDARD
      ? tariffPrice.STANDARD
      : undefined;
  }
};

export const extractFirstErrorFromSailings = (
  sailings: API.ExtendedSailing[],
  includeNonblocking: boolean = true
): API.Errors | undefined => {
  return sailings.reduce((prev, curr) => {
    return prev ? prev : getSailingErrors(curr, includeNonblocking)[0];
  }, undefined as API.Errors | undefined);
};

export const getSailingErrors = (sailing: Partial<API.ExtendedSailing>, block: boolean = true): API.Errors[] => {
  // TODO: Get rid of crawling the sailing object and implement proper error checking !

  const e = Object.keys(sailing).reduce((result: API.Errors[], current) => {
    const prop = sailing[current as keyof API.ExtendedSailing];

    if (prop?.hasOwnProperty('SPECIAL')) {
      const specialPrice: TariffPrice = prop as TariffPrice;
      if (API.isError(specialPrice.SPECIAL)) {
        return result.concat(specialPrice.SPECIAL as API.Errors);
      }
    }

    if (prop && API.isError(prop) && ((!block && !inNonblocking(prop)) || block)) {
      return result.concat(prop as API.Errors);
    }
    return result;
  }, [] as API.Errors[]);
  return e;
};

export const inNonblocking = (property: any) => {
  return property && typeof property === 'string' && API.Nonblocking.includes(property as API.Nonblocking);
};

export const sailingIsBefore = (
  sailing: { departureDate: string; departureTime: string },
  compareTo: { arrivalDate: string; arrivalTime: string } | Date
) => {
  const compare = compareTo instanceof Date ? compareTo : parseISO(compareTo.arrivalDate + ' ' + compareTo.arrivalTime);
  return parseISO(sailing.departureDate + ' ' + sailing.departureTime) < compare;
};

export const findClosest = (
  sailings: API.ExtendedSailing[],
  closestTo: API.Catalog | API.ExtendedSailing | undefined,
  favor?: string | null
): Maybe<API.ExtendedSailing> => {
  return Maybe.fromNullable(closestTo)
    .chain((closestTo) =>
      closestTo.hasOwnProperty('selected')
        ? getSelected(closestTo as API.Catalog)
        : Maybe.of(closestTo as API.ExtendedSailing)
    )
    .chain((selected) =>
      sailings.reduce<Maybe<API.ExtendedSailing>>((result, current) => {
        if (getSailingErrors(current).length > 0) {
          return result;
        }
        if (sailingIsBefore(current, selected)) {
          return result;
        }

        return result
          .chain((previous) => {
            if (favor && current.sailingCode === favor) {
              return Just(current);
            }
            const currentDep = parseISO(current.departureDate + ' ' + current.departureTime);
            const resultDep = parseISO(previous.departureDate + ' ' + previous.departureTime);
            const selectedArr = parseISO(selected.arrivalDate + ' ' + selected.arrivalTime);

            const realDiff = Math.abs(resultDep.valueOf() - selectedArr.valueOf());
            const newDiff = Math.abs(currentDep.valueOf() - selectedArr.valueOf());
            return newDiff < realDiff ? Just(current) : result;
          })
          .alt(Just(current));
      }, Nothing)
    );
};

export const findFirstWithoutErrors = (
  sailings: API.ExtendedSailing[],
  favor?: string | null
): Maybe<API.ExtendedSailing> => {
  return List.find((_) => _.sailingCode === favor, sailings)
    .filter((sailing) => getSailingErrors(sailing).length <= 0)
    .alt(List.find((sailing) => getSailingErrors(sailing).length <= 0, sailings));
};

// --- obsolete, no need to set loading for all departures. To be removed if no use found in future.
// export const setLoadingOnSameDepartureDate = <
//   T extends { departureDate: string; meta?: { loading: boolean } }
// >(
//   match: Maybe<T>,
//   all: T[],
//   loading: boolean
// ): T[] =>
//   match.mapOrDefault(
//     ({ departureDate }) =>
//       all.map((value) =>
//         value.departureDate === departureDate
//           ? { ...value, meta: { ...value.meta, loading } }
//           : value
//       ),
//     all
//   );

export const getNumberOfDeparturesPerDayBetweenPorts = (arrivalPort: API.Port, departurePort: API.Port): number =>
  portDepartureMap.get(encodePortPair([arrivalPort, departurePort])) ?? 0;

export const filterTimetablesByAvailability = (timetables: Timetable[]): [Timetable[], Timetable[]] => {
  const availables: Timetable[] = [];
  const unavailables: Timetable[] = [];

  timetables.forEach((timetable) => {
    if (isTimetableAvailable(timetable)) {
      availables.push(timetable);
    } else {
      reportUnavailableTimetable(timetable);
      unavailables.push(timetable);
    }
  });

  return [availables, unavailables];
};

export const filterTimetablesByErrorCode = (
  timetables: Timetable[],
  errorCodes: ErrorCode[]
): [Timetable[], Timetable[]] => {
  const errorCodeErrors: Timetable[] = [];
  const otherErrors: Timetable[] = [];

  timetables.forEach((timetable) => {
    if (
      typeof timetable.availabilityInfo === 'string' &&
      errorCodes.includes(timetable.availabilityInfo as ErrorCode)
    ) {
      errorCodeErrors.push(timetable);
    } else {
      otherErrors.push(timetable);
    }
  });

  return [errorCodeErrors, otherErrors];
};

const isTimetableAvailable = (timetable: Timetable): boolean => timetable.isAvailable && timetable.chargeTotal !== null;

const reportUnavailableTimetable = (timetable: Timetable): void => {
  logger.info(
    `Sailing ${timetable.sailingCode} is not available because: ${timetable.availabilityInfo} - '${timetable.availabilityReason}'`
  );
};

export const findEveningSailing = (sailings: Sailing[]): Sailing | undefined => {
  return sailings.find((sailing: Sailing) => !isDaySailing({ departureTime: sailing.departureTime }));
};

export const getOfferCode = (
  type: TripType,
  offerCode: string | undefined,
  isOfferCodeValid: boolean | undefined
): string | undefined => {
  if (isCruiseType(type)) {
    return getOfferCodeForCruise(offerCode, isOfferCodeValid === undefined || isOfferCodeValid);
  }
  if (isReturnForceMealOfferCode(offerCode) && type !== TripType.RETURN) {
    return undefined;
  }
  return isOfferCodeValid === undefined || isOfferCodeValid ? offerCode : undefined;
};

export const getOfferCodeForCatalogs = (
  type: TripType,
  offerCode: string | undefined,
  isOfferCodeValid: boolean | undefined,
  catalogs: Catalog[]
): string | undefined => {
  if (isReturnForceMealOfferCode(offerCode)) {
    if (type !== TripType.RETURN) {
      return undefined;
    }

    return isReturnWithinMealTimeLimit(catalogs) ? offerCode : undefined;
  }

  return getOfferCode(type, offerCode, isOfferCodeValid);
};

export const isOfferCodeValid = (meta: API.SailingMeta | undefined, tariff: TariffInput): boolean => {
  if (tariff === TariffInput.BOTH) {
    return true;
  }
  return Maybe.fromNullable(meta)
    .chainNullable((_) => _.offercodeViable)
    .map((_) => _[tariff] === true)
    .orDefault(false);
};

export const isOfferCodeValidForTariff = (meta: API.SailingMeta | undefined, tariff: API.Tariff): boolean => {
  return isOfferCodeValid(meta, tariff as unknown as TariffInput);
};

export const updateTimetable = (existingTimetable: Timetable[], incomingTimetable: Timetable[]) => {
  for (const candidate of incomingTimetable) {
    if (candidate && !existingTimetable.find((existing) => existing.sailingCode === candidate.sailingCode)) {
      existingTimetable.push(candidate);
    }
  }
};

export const getProducts = (selected: SelectedTypeMaybe) => {
  return selected.map((_) => _.products).chainNullable((products) => (API.isError(products) ? null : products));
};

export const getAccommodationProducts = (selected: SelectedTypeMaybe): API.ExtendedProduct[] => {
  return getProducts(selected)
    .chainNullable((_) => _.ACCOMMODATION)
    .orDefault([]);
};

export const getCruiseAccommodationProducts = (catalogs: API.Catalog[]): API.ExtendedProduct[] => {
  const selected = catalogs.map((c) => getSelected(c));
  const accommodationProducts = selected.map((s) => getAccommodationProducts(s));
  const commonAccommodations = accommodationProducts.reduce((availableAccommodation, sailingAccommondation) => {
    return availableAccommodation.filter((a) => sailingAccommondation.some((sa) => sa.code === a.code));
  });
  return commonAccommodations;
};

const isBetweenTimes = ({ departureTime }: { departureTime: string }, start: string, end: string) => {
  const now = new Date(Date.now());
  const parseTime = parse(now, 'HH:mm');
  const isAfterStart = isAfter(parseTime(start));
  const isBeforeEnd = isBefore(parseTime(end));
  const departure = parseTime(departureTime);
  return isAfterStart(departure) && isBeforeEnd(departure);
};

const preferredSailingTimes = [
  { start: '06:00', end: '17:59' },
  { start: '00:00', end: '05:59' },
  { start: '18:00', end: '23:59' },
];

/**
 * Finds preferred sailing for each day in catalog that has sailings
 *
 * @param catalog
 * @returns one sailing per day
 */
export const getPreferredSailings = (catalog: Catalog): ExtendedSailing[] => {
  const sailingsByDate = catalog.sailings.reduce((sailings, curr: ExtendedSailing) => {
    if (sailings[curr.departureDate] === undefined) {
      sailings[curr.departureDate] = [curr];
    } else {
      sailings[curr.departureDate].push(curr);
    }
    return sailings;
  }, {} as any);

  return Object.keys(sailingsByDate).reduce((preferredSailings: ExtendedSailing[], currDate: string) => {
    const sailingsOnDate: ExtendedSailing[] = sailingsByDate[currDate];

    let preferredSailing: ExtendedSailing | undefined;
    let index = 0;
    while (!preferredSailing && index < preferredSailingTimes.length) {
      preferredSailing = sailingsOnDate.find((sailing) => {
        return isBetweenTimes(sailing, preferredSailingTimes[index].start, preferredSailingTimes[index].end);
      });
      index++;
    }

    if (!!preferredSailing) {
      return [...preferredSailings, preferredSailing];
    }

    return preferredSailings;
  }, [] as ExtendedSailing[]);
};

export const legToIndex = (leg: number) => leg - 1;
export const indexToLeg = (index: number) => index + 1;

export const getSailingsFilter = ({ type, legMeta }: { type: TripType; legMeta: LegMeta }) => {
  switch (type) {
    case TripType.DAYCRUISE:
      return isDayCruiseTime;
    case TripType.OVERNIGHT_CRUISE:
      return (sailing: ExtendedSailing) => !legMeta.cruiseNotAvailableShips.includes(sailing.shipName || '');
    default:
      return (sailing: ExtendedSailing) => true;
  }
};

export const isReturnWithinMealTimeLimit = (catalogs: Catalog[]) => {
  if (catalogs.length === 1) {
    return false;
  }
  const departureDatetime = convertStringToDate(
    catalogs[0]?.selected?.departureDate,
    catalogs[0]?.selected?.departureTime
  );

  const arrivalDatetime = convertStringToDate(catalogs[1]?.selected?.arrivalDate, catalogs[1]?.selected?.arrivalTime);

  return returnInSelectedTime(arrivalDatetime, departureDatetime);
};

const returnInSelectedTime = (arrivalDatetime: Date | undefined, departureDatetime: Date | undefined) => {
  if (arrivalDatetime !== undefined && departureDatetime !== undefined) {
    const hoursDifference: number =
      Math.abs(departureDatetime.getTime() - arrivalDatetime.getTime()) / (1000 * 60 * 60);

    return hoursDifference <= settings.returnOfferCodeTimeLimit;
  }
  return false;
};

const convertStringToDate = (dateString: string | undefined, timeString: string | undefined) => {
  return dateString !== undefined ? new Date(`${dateString}T${timeString}`) : undefined;
};
