import { SelectedListType } from '../../design-system/components/TreeSelect/SelectedList';
import { Language } from '../../Language.context';
import { Currency, PassengerType, TimetableQuery } from '../types';

export enum TripType {
  RETURN = 'RETURN',
  ONEWAY = 'ONEWAY',
  OVERNIGHT_CRUISE = 'OVERNIGHT_CRUISE',
  DAYCRUISE = 'DAYCRUISE',
  //MULTILEG = 'MULTILEG',
}
export type TripTypeString = keyof typeof TripType;

export enum Port {
  DETRV = 'DETRV',
  FIHEL = 'FIHEL',
  FILAN = 'FILAN',
  FINLI = 'FINLI',
  SEKPS = 'SEKPS',
  SEMMA = 'SEMMA',
  PLSWI = 'PLSWI',
}

export type PortString = keyof typeof Port;

export interface Passengers {
  [PassengerType.ADULT]: number;
  [PassengerType.JUNIOR]: number;
  [PassengerType.CHILD]: number;
  [PassengerType.INFANT]: number;
}

export interface Form {
  index: number;
  searchedDate: string;
  selectedDate: string;
  params: {
    departurePort: Port;
    arrivalPort: Port;
    startDate: string;
    endDate: string;
    numberOfDays: string;
  };
}

export interface SearchParams {
  currency: Currency;
  forms: Form[];
  language: Language;
  passengers: Passengers;
  petCount: number;
  searchedAt?: number;
  starclub: boolean;
  offerCode?: string;
  type: TripType;
  vehicles: SelectedListType;
  legMeta: LegMeta;
  portMap?: PortMap[];
  range?: number;
}

export interface LegMeta {
  cruiseNotAvailableShips: string[];
}

export interface LineIdentity {
  name: string;
  code: Port;
  connections?: LineIdentity[];
}

export interface PortMap {
  from: LineIdentity;
  to: LineIdentity[];
}

export type TimetableParams = TimetableQuery & {
  startDate: string;
  endDate: string;
  departurePort: Port;
  arrivalPort: Port;
  passengers: Passengers;
  petCount: number;
};
