import Joi from '@hapi/joi';
import { format, parseISO } from 'date-fns';
import { List } from 'purify-ts';
import React, { FunctionComponent, useContext, useEffect, useReducer, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Alert from '../../design-system/components/Alert';
import Button from '../../design-system/components/Button';
import Checkbox from '../../design-system/components/Checkbox';
import Container, { Cell, Subgrid } from '../../design-system/components/Container';
import InputGroup, {
  InputSelect,
  useInputGroupState,
  useInputSelectState,
} from '../../design-system/components/InputGroup';
import LinkButton from '../../design-system/components/LinkButton';
import Logo from '../../design-system/components/Logo';
import RichText from '../../design-system/components/RichText';
import Select, { useSelectState } from '../../design-system/components/Select';
import SelectCount from '../../design-system/components/SelectCount';
import { H1, H3, P } from '../../design-system/components/Text';
import { Sticky } from '../../design-system/helpers/components';
import { hooks } from '../../design-system/helpers/mixins';
import { maxPassengers } from '../../fsm/constants';
import { combineForms, LinkedPartial, searchToParams } from '../../fsm/linkedState';
import { updateHistoryAction } from '../../fsm/react';
import { defaultDaycruisePortsByLang, defaultPortsByLang } from '../../fsm/search/rules';
import searchMachine, { SearchContext } from '../../fsm/search/searchMachine';
import {
  Currency,
  Form,
  Language as LanguageType,
  PassengerType,
  PetType,
  Port,
  PortMap,
  SearchError,
  SearchParams,
  TripType,
} from '../../fsm/types';
import { Language, LanguageContext, setApplicationLanguage } from '../../Language.context';
import { settings } from '../../settings';
import logger from '../../utils/logging';
import { useMachine } from '../../xstate-react';
import Notification from '../common/Notification';
import Actions from './Actions';
import { formDates, startToEndDate } from './common';
import RangeForm from './forms/RangeForm';
import SingleForm from './forms/SingleForm';
import PaymentMethods from './PaymentMethods';
import SearchFooter from './SearchFooter';
import VehicleSelect from './VehicleSelect';
import { isCruiseType } from '../../fsm/api/cruise';
import { Tree } from '../../storyblok';

interface SearchProps extends RouteComponentProps<any> {
  savedContext: SearchParams | undefined;
  submitSearch: (searchParams: SearchParams) => void;
}

type FormActions =
  | {
      type: 'ADD';
      payload: {
        searchedDate: string;
        selectedDate: string;
        params: {
          departurePort: Port;
          arrivalPort: Port;
          startDate: string;
          endDate: string;
          numberOfDays: string;
        };
      };
    }
  | { type: 'REMOVE'; payload: { index: number } }
  | {
      type: 'EDIT';
      payload: { form: Form; range: number };
    };

type FormState = { forms: Form[]; resetCurrency: boolean };

const formReducer = ({ forms, resetCurrency }: FormState, action: FormActions): FormState => {
  switch (action.type) {
    case 'ADD':
      return {
        forms: [
          ...forms,
          {
            index:
              (Math.max.apply(
                Math,
                forms.map(({ index }) => index || -1)
              ) || 1) + 1,
            ...action.payload,
          },
        ],
        resetCurrency: false,
      };
    case 'REMOVE':
      return {
        forms: forms.reduce(
          (result, form) => (form.index !== action.payload.index ? result.concat(form) : result),
          [] as Form[]
        ),
        resetCurrency: false,
      };
    case 'EDIT':
      return {
        forms: forms.map((form) => {
          const selectedDate = parseISO(form.selectedDate);
          const actionSelectedDate = parseISO(action.payload.form.selectedDate);
          return form.index === action.payload.form.index
            ? { ...action.payload.form }
            : form.index > action.payload.form.index
            ? {
                ...form,
                selectedDate: selectedDate < actionSelectedDate ? action.payload.form.selectedDate : form.selectedDate,
                params: {
                  ...form.params,
                  ...(selectedDate < actionSelectedDate
                    ? formDates(actionSelectedDate, action.payload.range)
                    : {
                        startDate: form.params.startDate,
                        endDate: form.params.endDate,
                        numberOfDays: form.params.numberOfDays,
                      }),
                },
              }
            : form;
        }),
        // Reset currency when changing the departure port for first step.
        // Otherwise we keep the value already in state as there might be
        // multiple actions executed at once.
        resetCurrency:
          action.payload.form.index === 0
            ? action.payload.form.params.departurePort !== forms[0].params.departurePort
            : resetCurrency,
      };
    default:
      break;
  }
  return { forms, resetCurrency };
};

const isPortPresentInForms = (portToFind: Port, forms: Form[], selectedTripType: TripType): boolean => {
  return selectedTripType === TripType.ONEWAY
    ? forms[0].params.arrivalPort === portToFind || forms[0].params.departurePort === portToFind
    : forms.some(({ params }) => params.arrivalPort === portToFind || params.departurePort === portToFind);
};

// SEK is only allowed when FIHEL is not included.
const defaultCurrencyFor = (language: Language, forms: Form[], selectedTripType: TripType): Currency => {
  if (language === LanguageType.SV && !isPortPresentInForms(Port.FIHEL, forms, selectedTripType)) {
    return Currency.SEK;
  } else if (language === LanguageType.PL && isPortPresentInForms(Port.PLSWI, forms, selectedTripType)) {
    return Currency.PLN;
  } else return Currency.EUR;
};

const showCurrencySelection = (forms: Form[], selectedTripType: TripType): boolean =>
  !isPortPresentInForms(Port.FIHEL, forms, selectedTripType);

const legMetaForRoute = (sbLegMeta: Tree, departurePort: string, arrivalPort: string) => {
  return Object.values(sbLegMeta).find((meta) => {
    const [departure, arrival] = meta.slug.split('-');
    return departurePort === departure && arrivalPort === arrival;
  });
};

/**
 * Available trip types shown in the search view.
 * TODO: Add trip type options for multileg here when business decides about their usage.
 */
const availableTripTypes: TripType[] = [
  TripType.RETURN,
  TripType.ONEWAY,
  TripType.OVERNIGHT_CRUISE,
  TripType.DAYCRUISE,
];

interface TripTypeProps {
  forms: Form[];
  tripType: TripType;
  range: number;
  portMap: PortMap[];
  updateForm: (form: Form) => void;
  cruisePorts?: Port[];
  dayCruisePorts?: Port[];
}

export const TripTypeForm: FunctionComponent<TripTypeProps> = ({
  forms,
  tripType,
  range,
  portMap,
  updateForm,
  cruisePorts,
  dayCruisePorts,
}) => {
  switch (tripType) {
    // multileg trips not available yet
    /*case 'TripType.MULTILEG':
      return (
        <>
          {forms.map((form) => (
            <SingleForm
              key={form.index}
              type={typeState.state}
              form={form}
              portMap={context.portMap}
              range={range}
              disableBefore={forms.find((f) => f.index === form.index - 1)?.params.startDate}
              update={updateForm}
            />
          ))}
          <Subgrid variant="dense">
            <LinkButton icon="plus" onClick={addForm} position="start">
              {sbSearchForm?.content['add_leg']}
            </LinkButton>
            <LinkButton icon="minus" onClick={removeForm} position="end">
              {sbSearchForm?.content['remove_leg']}
            </LinkButton>
          </Subgrid>
        </>
      );*/
    case TripType.RETURN:
      return (
        <RangeForm
          type={tripType}
          form={forms[0]}
          returnForm={forms[1]}
          range={range}
          portMap={portMap}
          update={updateForm}
        />
      );
    case TripType.OVERNIGHT_CRUISE:
      return (
        <SingleForm
          type={tripType}
          form={forms[0]}
          returnForm={forms[1]}
          portMap={portMap}
          range={range}
          update={updateForm}
          cruisePorts={cruisePorts}
        />
      );
    case TripType.ONEWAY:
      return (
        <SingleForm
          type={tripType}
          form={forms[0]}
          returnForm={forms[1]}
          portMap={portMap}
          range={range}
          update={updateForm}
        />
      );
    case TripType.DAYCRUISE:
      return (
        <SingleForm
          type={tripType}
          form={forms[0]}
          returnForm={forms[1]}
          portMap={portMap}
          range={range}
          update={updateForm}
          cruisePorts={dayCruisePorts}
        />
      );
    default:
      return (
        <RangeForm
          type={tripType}
          form={forms[0]}
          returnForm={forms[1]}
          range={range}
          portMap={portMap}
          update={updateForm}
        />
      );
  }
};

const SearchView: FunctionComponent<SearchProps> = ({ savedContext, submitSearch, ...props }) => {
  const [current, send] = useMachine(searchMachine, {
    actions: {
      onSubmitSearch: ({ searchParams }: SearchContext) => {
        searchParams && submitSearch(searchParams);
      },
      updateHistory: updateHistoryAction(props),
    },
    context: { searchParams: savedContext, errorMsg: [], portMap: [] },
  });

  const { history } = props;
  const { context } = current;
  const { language, setCurrency, currency: ctxCurrency } = useContext(LanguageContext);

  const [locationParams] = useState<LinkedPartial>(props.location.search ? searchToParams(props.location.search) : {});

  const [selectableCurrencies, setSelectableCurrenies] = useState({});
  const currency = locationParams.currency ?? ctxCurrency;

  const typeState = useSelectState({
    initial: locationParams?.type
      ? locationParams.type
      : context.searchParams
      ? context.searchParams.type
      : TripType.ONEWAY,
  });

  const passengerCountFor = (passengerType: PassengerType, defaultCount: number): number =>
    locationParams?.passengers && locationParams?.passengers[passengerType]
      ? locationParams.passengers[passengerType]
      : context.searchParams
      ? context.searchParams.passengers[passengerType]
      : defaultCount;

  const [passengerCounts, setPassengerCounts] = useState(() => ({
    ADULT: passengerCountFor(PassengerType.ADULT, 1),
    JUNIOR: passengerCountFor(PassengerType.JUNIOR, 0),
    CHILD: passengerCountFor(PassengerType.CHILD, 0),
    INFANT: passengerCountFor(PassengerType.INFANT, 0),
  }));

  const setPassengerCountFor =
    (passengerType: PassengerType): ((count: number) => void) =>
    (count) =>
      setPassengerCounts({ ...passengerCounts, [passengerType]: count });

  const [offerCode, setOfferCode] = useState<string | undefined>(
    locationParams.offerCode
      ? locationParams.offerCode
      : context.searchParams
      ? context.searchParams.offerCode
      : undefined
  );

  const [petCount, setPetCount] = useState(() =>
    locationParams?.petCount ? locationParams.petCount : context.searchParams ? context.searchParams.petCount : 0
  );
  const [vehicles, setVehicles] = useState(() =>
    locationParams?.vehicles ? locationParams.vehicles : context.searchParams ? context.searchParams.vehicles : []
  );

  const [vehicleRestrictionList, setVehicleRestrictionList] = useState(() => undefined as string[] | undefined);

  const [sbSearchForm, ref] = hooks.useStoryblokComponent<HTMLDivElement>({
    path: 'search.sailings_search_form',
  });

  const sbSearchErrorCodes = hooks.useStoryblokDatasource('search-error-codes');

  const sbPassengers = hooks.useStoryblokComponents('products/passengers');
  const sbTravelTypeOptions = hooks.useStoryblokDatasource('travel-type-options');

  const sbDefaultCruisePorts = hooks.useStoryblokComponent({
    path: 'search.cruise_ports',
  })[0]?.content?.cruise_defaults;

  const sbDefaultDayCruisePorts = hooks.useStoryblokComponent({
    path: 'search.daycruise_ports',
  })[0]?.content?.daycruise_defaults;

  // const width = window.innerWidth;
  // const range = width && width < 800 ? (width < 570 ? 1 : 2) : 3;
  const range = 1;

  const startDefault = new Date();

  const defaultPort: { from: Port; to: Port } =
    typeState.state === TripType.DAYCRUISE 
      ? defaultDaycruisePortsByLang[language]
      : defaultPortsByLang[language];

  const createForms = (): Form[] => {
    const forms =
      context.searchParams?.forms && context.searchParams?.forms.length >= 2
        ? context.searchParams.forms
        : context.searchParams?.forms && context.searchParams.forms.length >= 1
        ? [
            ...context.searchParams?.forms,
            {
              index: 1,
              searchedDate: format(startDefault, 'yyyy-MM-dd'),
              selectedDate: format(startToEndDate(startDefault, range * 2), 'yyyy-MM-dd'),
              params: {
                departurePort:
                  context.searchParams?.forms[0].params.arrivalPort || defaultPort.from || defaultPortsByLang.EN.from,
                arrivalPort:
                  context.searchParams?.forms[0].params.departurePort || defaultPort.to || defaultPortsByLang.EN.to,
                ...formDates(startToEndDate(startDefault, range * 2), range),
              },
            },
          ]
        : [
            {
              index: 0,
              searchedDate: format(startDefault, 'yyyy-MM-dd'),
              selectedDate: format(startDefault, 'yyyy-MM-dd'),
              params: {
                departurePort: defaultPort.from || defaultPortsByLang.EN.from,
                arrivalPort: defaultPort.to || defaultPortsByLang.EN.to,
                ...formDates(startDefault, range),
              },
            },
            {
              index: 1,
              searchedDate: format(startDefault, 'yyyy-MM-dd'),
              selectedDate: format(startToEndDate(startDefault, range * 2), 'yyyy-MM-dd'),
              params: {
                departurePort: defaultPort.to || defaultPortsByLang.EN.to,
                arrivalPort: defaultPort.from || defaultPortsByLang.EN.from,
                ...formDates(startToEndDate(startDefault, range * 2), range),
              },
            },
          ];

    return locationParams.forms ? combineForms(locationParams.forms, forms) : forms;
  };

  const [formState, dispatchForms] = useReducer(formReducer, {
    forms: createForms(),
    resetCurrency: true,
  });

  const { forms, resetCurrency } = formState;

  useEffect(() => {
    if (resetCurrency || !showCurrencySelection(forms, typeState.state)) {
      setCurrency(defaultCurrencyFor(language, forms, typeState.state));
    }
  }, [forms, language, resetCurrency, setCurrency]);

  useEffect(() => {
    if (isPortPresentInForms(Port.PLSWI, forms, typeState.state)) {
      setSelectableCurrenies({
        EUR: 'EUR',
        SEK: 'SEK',
        PLN: 'PLN',
      });
    } else {
      setSelectableCurrenies({
        EUR: 'EUR',
        SEK: 'SEK',
      });
    }
  }, [forms]);

  const addForm = () => {
    const lastForm = forms[forms.length - 1];
    dispatchForms({
      type: 'ADD',
      payload: {
        searchedDate: format(startDefault, 'yyyy-MM-dd'),
        selectedDate: format(startToEndDate(parseISO(lastForm.params.endDate), range), 'yyyy-MM-dd'),
        params: {
          departurePort: lastForm.params.arrivalPort,
          arrivalPort:
            context.portMap.find((p: PortMap) => p.from.code === lastForm.params.arrivalPort)?.to[0].code || Port.DETRV,
          ...formDates(startToEndDate(parseISO(lastForm.params.endDate), range), range),
        },
      },
    });
  };

  const removeForm = () => {
    if (forms.length > 2) {
      dispatchForms({ type: 'REMOVE', payload: { index: forms.length - 1 } });
    } else {
      typeState.setState(TripType.ONEWAY);
    }
  };

  const updateForm = (form: Form) => {
    dispatchForms({
      type: 'EDIT',
      payload: {
        form,
        range,
      },
    });
  };

  const tripProps = {
    ...typeState,
    choices: availableTripTypes.map((value) => ({
      value,
      label: sbTravelTypeOptions[value] || '',
    })),
    label: sbSearchForm?.content['travel_type'] || '',
  };

  const currencyProps = {
    ...useInputSelectState({
      label: sbSearchForm?.content.currency,
      htmlFor: 'currency',
      onReady: (_: string, currency: string) => setCurrency(currency as Currency),
      key: 'currency',
      initial: currency as string,
      validationSchema: Joi.object({ currency: Joi.string().allow(...Object.keys(Currency)) }),
    }),
  };

  const totalCount = Object.values(passengerCounts).reduce((sum, count) => sum + count, 0);
  const totalMin = 1;
  const min = 0;
  const maxPets = settings.maxPetsInCabin; // Initially we limit pets into one cabin. maxPetsOnTrip could be considered.

  const getControl = (
    id: string,
    value: number,
    setValue: (value: number) => void,
    requireAdult: boolean = false,
    limitAll = true,
    isPet = false
  ) => {
    const comp = sbPassengers[id];

    return {
      id: `search-${id}`,
      label: comp ? comp.content.title : '',
      value,
      min:
        !isPet && !requireAdult
          ? id === PassengerType.ADULT && passengerCounts.JUNIOR > min
            ? min
            : id === PassengerType.JUNIOR && passengerCounts.ADULT > min
            ? min
            : totalMin
          : min,
      max:
        requireAdult && passengerCounts.ADULT <= 0
          ? 0
          : limitAll
          ? totalCount >= maxPassengers
            ? value
            : maxPassengers
          : isPet
          ? maxPets
          : maxPassengers,
      setValue,
      description: comp?.content['description'] || undefined,
    };
  };

  const passengersProps = {
    groups: [
      {
        labelSingle: sbSearchForm?.content['passenger_single'] || '',
        labelMultiple: sbSearchForm?.content['passenger_plural'] || '',
        controls: [
          getControl(PassengerType.ADULT, passengerCounts.ADULT, setPassengerCountFor(PassengerType.ADULT)),
          getControl(PassengerType.JUNIOR, passengerCounts.JUNIOR, setPassengerCountFor(PassengerType.JUNIOR)),
          getControl(PassengerType.CHILD, passengerCounts.CHILD, setPassengerCountFor(PassengerType.CHILD), true),
          getControl(PassengerType.INFANT, passengerCounts.INFANT, setPassengerCountFor(PassengerType.INFANT), true),
        ],
      },
      {
        labelSingle: sbSearchForm?.content['pet_single'] || '',
        labelMultiple: sbSearchForm?.content['pet_plural'] || '',
        controls: [getControl(PetType.PET, petCount, setPetCount, false, false, true)],
      },
    ],
    placeholder: sbSearchForm?.content['select_passengers'],
    submit: sbSearchForm?.content['ready_button'],
  };

  //Watch adult count and remove children/infants if adultCount == 0
  useEffect(() => {
    if (passengerCounts.ADULT <= 0 && (passengerCounts.CHILD > 0 || passengerCounts.INFANT > 0)) {
      setPassengerCounts({ ...passengerCounts, CHILD: 0, INFANT: 0 });
    }
  }, [passengerCounts, setPassengerCounts]);

  const [sbDiscountForm, discountRef] = hooks.useStoryblokComponent<HTMLDivElement>({
    path: 'search.discount_form',
  });

  const sbLegMeta = hooks.useStoryblokComponents('components/leg-meta');

  const [starclub, setStarclub] = useState(
    locationParams?.starclub ? locationParams.starclub : context.searchParams ? context.searchParams.starclub : false
  );

  useEffect(() => {
    // Change of trip type or formState or vehicles has been changed
    onCheckParams(); // Trigger search machine and do search parameter checking/validation
  }, [typeState.state, formState.forms, vehicles, offerCode]);

  const offerCodeInput = {
    ...useInputGroupState({
      label: sbDiscountForm?.content.offercode_label,
      forceValidation: true,
      htmlFor: 'offercode',
      onReady: (_, val) => {
        setOfferCode(val.trim());
      },
      key: 'offercode',
      upperCase: true,
      initial: locationParams?.offerCode
        ? locationParams.offerCode
        : context.searchParams
        ? context.searchParams.offerCode || undefined
        : '',
    }),
  };

  useEffect(() => {
    const { params } = forms[0];
    const { arrivalPort, departurePort } = params;
    const legMeta = legMetaForRoute(sbLegMeta, departurePort, arrivalPort);
    if (legMeta?.content) {
      const {
        content: { allowed_vehicles, allowed_vehicles_for_cruise, allowed_vehicles_for_daycruise },
      } = legMeta;

      if (!isCruiseType(typeState.state)) {
        if (allowed_vehicles?.length === 0) setVehicleRestrictionList(undefined);
        else setVehicleRestrictionList(allowed_vehicles);
      }

      if (typeState.state === TripType.OVERNIGHT_CRUISE && allowed_vehicles_for_cruise) {
        setVehicleRestrictionList(allowed_vehicles_for_cruise);
        setVehicles(vehicles.filter((v) => allowed_vehicles_for_cruise.includes(v.uuid)));
      }

      if (typeState.state === TripType.DAYCRUISE && allowed_vehicles_for_daycruise) {
        setVehicleRestrictionList(allowed_vehicles_for_daycruise);
        setVehicles(vehicles.filter((v) => allowed_vehicles_for_daycruise.includes(v.uuid)));
      }
    } else {
      setVehicleRestrictionList(undefined);
    }
  }, [forms, typeState.state, sbLegMeta]);

  const createParams = (): SearchParams => {
    const { arrivalPort, departurePort } = forms[0].params;
    const params: SearchParams = {
      currency,
      language,
      starclub,
      offerCode,
      vehicles,
      searchedAt: new Date().getTime(),
      type: typeState.state,
      passengers: passengerCounts,
      petCount,
      forms:
        typeState.state === TripType.ONEWAY
          ? [forms[0]]
          : typeState.state === TripType.RETURN || isCruiseType(typeState.state)
          ? [forms[0], forms[1]]
          : forms,
      legMeta: {
        cruiseNotAvailableShips:
          legMetaForRoute(sbLegMeta, departurePort, arrivalPort)?.content.cruise_not_available_ships ||
          ([] as string[]),
      },
      portMap: context.portMap,
      range: range,
    };
    logger.info('createParams', params, 'typeState:', typeState, 'forms:', forms, offerCode);
    return params;
  };

  const onSearch = () => send({ type: 'SEARCH', searchParams: createParams(), sbLegMeta });
  const onCheckParams = () => send({ type: 'CHECK', searchParams: createParams(), sbLegMeta });

  const addLanguageToHistory = (language: Language) => history.push(`/${language}/search`);
  const changeLanguage = (language: Language) => {
    addLanguageToHistory(language);
    setApplicationLanguage(language);
  };

  const getLocalisedErrorMessage = (message: string): string => {
    // Localised error strings may not be loaded when error occurs. As fallback use original message if exists.
    return sbSearchErrorCodes[message] || message || 'Unknown error';
  };

  let errorMessage: string | undefined; // Undefined here means no error occured, no error shown on page
  let errorCode: string | null = null;
  if (context.errorMsg?.length) {
    errorMessage = List.head(context.errorMsg).map(getLocalisedErrorMessage).orDefault('Unknown error');
    errorCode = List.head(context.errorMsg).extractNullable() as string | null;
  }

  const isLegMetaLoading = !Object.keys(sbLegMeta).length;

  // Load failure notification, special case
  const [sbLoadFailureNotification, loadFailureRef] = hooks.useStoryblokComponent<HTMLDivElement>({
    path: 'load-failure-notification',
  });
  const sbLoadFailureRichText = hooks.useStoryblokRichText(sbLoadFailureNotification?.content.content);

  const allLegs = forms.map(({ params }) => [params.departurePort, params.arrivalPort] as [Port, Port]);
  const legs = (typeState.state === TripType.RETURN ? allLegs : [allLegs[0]]) as [Port, Port][];

  const href = `https://www.finnlines.com/${language !== 'EN' ? language.toLocaleLowerCase() : ''}`;

  return (
    <Sticky.Scroll full>
      <Container variant="search" ref={ref}>
        <Subgrid variant="dense" cols={3} header>
          <H1>
            <a rel="noopener noreferrer" target="_blank" href={href}>
              <Logo />
            </a>
          </H1>
          <Actions handleLanguageChange={changeLanguage} />
        </Subgrid>

        <Cell cols={3}>
          <Notification legs={legs} storyblokPath="search.notification-list" />
          <Select {...tripProps}>{sbSearchForm?.content['select_travel_type']}</Select>
        </Cell>

        <TripTypeForm
          forms={forms}
          tripType={typeState.state}
          range={range}
          portMap={context.portMap}
          updateForm={updateForm}
          cruisePorts={sbDefaultCruisePorts}
          dayCruisePorts={sbDefaultDayCruisePorts}
        ></TripTypeForm>

        <Subgrid variant="dense">
          <SelectCount {...passengersProps}>{sbSearchForm?.content['select_passengers']}</SelectCount>
          {(vehicleRestrictionList === undefined || vehicleRestrictionList?.length > 0) && (
            <VehicleSelect
              passengers={passengerCounts}
              setVehicles={setVehicles}
              initial={vehicles}
              vehicleRestrictionList={vehicleRestrictionList}
            />
          )}
        </Subgrid>

        {showCurrencySelection(forms, typeState.state) && (
          // TODO: Should be shown when one of the ports in each of the legs is Swedish
          <Cell>
            <InputSelect {...currencyProps}>
              {Object.keys(selectableCurrencies).map((value) => (
                <option key={value} value={value}>
                  {value}
                </option>
              ))}
            </InputSelect>
          </Cell>
        )}

        <Cell as={Subgrid} variant="dense" cols={3} itemAlign="center">
          <Checkbox value="starclub" checked={starclub} onChange={setStarclub} inline>
            {sbDiscountForm?.content.starclub_label}
          </Checkbox>
          <InputGroup {...offerCodeInput} />
        </Cell>

        <Cell cols={3}>
          {errorMessage &&
            (errorCode === SearchError.LOAD_FAIL ? (
              <Alert severity="error" ref={loadFailureRef}>
                {sbLoadFailureRichText ? <RichText>{sbLoadFailureRichText}</RichText> : <H3>Loading error</H3>}
              </Alert>
            ) : (
              <Alert severity="error">{errorMessage}</Alert>
            ))}
        </Cell>

        <Cell cols={3}>
          <Button fullWidth round disabled={!!errorMessage || isLegMetaLoading} onClick={onSearch}>
            {sbSearchForm?.content['search_button']}
          </Button>
        </Cell>

        <PaymentMethods />
        <SearchFooter />
      </Container>
    </Sticky.Scroll>
  );
};

export default SearchView;
