import { List, Maybe } from 'purify-ts';
import logger from '../../utils/logging';
import {
  AccommodationError,
  BookingQuote,
  ErrorCode,
  ExtendedAccommodation,
  ExtendedPassengerAndPet,
  ExtendedVehicle,
  Leg,
  PriceError,
  Product,
  ProductError,
  Products,
  QuotesByTariff,
  SailingMeta,
  Tariff,
  TariffPrice,
  TariffPriceOrError,
  Timetable,
  VehicleError,
  isChargeInfo,
  isPassengerInput,
  isTariffPrice,
} from '../types';
import { createTariffPrice } from './sailingUtils';

export const priceError = <A extends { price?: { [leg: number]: TariffPrice | PriceError } }>(objects: A[]): A[] => {
  return objects.map((p) => ({
    ...p,
    price: p.price ? Object.keys(p.price).reduce((k, c) => ({ ...k, [c]: PriceError.UNSPECIFIED }), p.price) : p.price,
  }));
};

const updateVehicleCapacities = (selected: ExtendedVehicle[], vehicles: Product[]): VehicleError | undefined => {
  if (vehicles.length <= 0) {
    return VehicleError.NOT_AVAIL_VEHICLES;
  } else {
    for (let i = 0; i < selected.length; i++) {
      const vehicle = selected[i];
      const asProduct = vehicles.find((v) => v.code === vehicle.type);
      if (!asProduct) {
        return VehicleError.NOT_AVAIL_VEHICLES;
      }
      if (!asProduct?.capacity?.available || asProduct.capacity.available <= 0) {
        return VehicleError.NO_VEHICLE_SPACE;
      }
      asProduct.capacity.available--;
    }

    return undefined;
  }
};

export const vehicleError = (
  vehicles: ExtendedVehicle[],
  products: Products | ProductError,
  leg: number
): VehicleError | undefined => {
  if (typeof products === 'string') {
    return undefined;
  } else {
    return updateVehicleCapacities(
      vehicles.filter((_) => _.legs?.includes(leg)),
      [...(products.BIKE || []), ...(products.VEHICLE || [])]
    );
  }
};

export const mapTariffPriceToObjectById = <
  A extends {
    id: number;
    legs: Leg[];
  },
  B extends {
    id: number;
    price?: { [leg: number]: TariffPrice | PriceError };
  }
>(
  all: A[],
  object: B,
  tariff: Tariff
): B => {
  List.find(({ id }) => id === object.id, all).map(({ legs }) =>
    legs.forEach(({ leg, chargeInfo }) => {
      const priceForLeg = createTariffPrice(object.price && object.price[leg], tariff, chargeInfo);

      object.price = {
        ...(object.price || {}),
        [leg]: priceForLeg || PriceError.UNSPECIFIED,
      };
    })
  );

  return object;
};

export const isOfferCodeViableForTariff = (
  quote: BookingQuote | undefined,
  sailing: {
    sailingCode: string;
    meta?: SailingMeta;
  },
  tariff: Tariff,
  offerCode: string | undefined
) => {
  return Maybe.fromNullable(quote)
    .chainNullable((_) => _.sailings)
    .chain((sailings) => List.find((_) => _.sailingCode === sailing.sailingCode, sailings))
    .chainNullable((_) => _.offerCode != null && _.offerCode === offerCode)
    .alt(
      Maybe.fromNullable(sailing.meta)
        .chainNullable((_) => _.offercodeViable)
        .map((_) => _[tariff])
    )
    .orDefault(false);
};

export const SailPriceError = (error: any): PriceError | undefined => {
  const errorCode: string = error.errorCode || error.message || (error.errors ? error.errors[0]?.errorType : undefined);

  logger.warn('SailPriceError:', error, '-> errorCode:', errorCode);

  switch (errorCode) {
    case undefined:
      return undefined;
    case ErrorCode.SAILING_ONBOARDING:
      return PriceError.SAILING_ONBOARDING;
    case ErrorCode.NO_PRICE_AVAILABLE:
      return PriceError.NO_PRICE;
    case ErrorCode.SAILING_OVERLAPS:
      return PriceError.SAILING_OVERLAPS;
    case ErrorCode.ACCOMMODATION_NOT_AVAILABLE:
      return PriceError.ACCOMMODATION_NOT_AVAILABLE;
    case ErrorCode.CABIN_REQUIRED_PET: // Backend responds with this code when there are pets, but no default accommodation
      return PriceError.NO_AVAIL_PET_CABINS; // TODO: Special case, internal error due to failed default accomodation creation
    case ErrorCode.NO_AVAIL_PET_CABINS:
      return PriceError.NO_AVAIL_PET_CABINS;
    case ErrorCode.VEHICLE_REQUIRED:
      return PriceError.VEHICLE_REQUIRED;
    case ErrorCode.NO_CRUISE_AVAILABLE:
      return PriceError.NO_CRUISE_AVAILABLE;
    case ErrorCode.NO_VEHICLE_SPACE:
      return PriceError.NO_VEHICLE_SPACE;
    case ErrorCode.NO_PASSENGER_CAPACITY:
      return PriceError.NO_PASSENGER_CAPACITY;
    case AccommodationError.NOT_AVAIL_ACCOMMODATIONS:
      return PriceError.NOT_AVAIL_ACCOMMODATIONS;
    case ErrorCode.SERVER_ERROR:
    default:
      return PriceError.UNSPECIFIED; // Dangerous return value, could be mixed with any other error type later on
  }
};

export const hasPrice = (price: TariffPriceOrError | undefined | null) => {
  return price && typeof price !== 'string' && isTariffPrice(price) && isChargeInfo(price.SPECIAL);
};

export const getPriceOrError = (price: TariffPriceOrError | undefined | null, error: any) => {
  const sailPriceError = SailPriceError(error);
  return sailPriceError === PriceError.NO_PASSENGER_CAPACITY || sailPriceError === PriceError.NO_VEHICLE_SPACE
    ? sailPriceError
    : hasPrice(price)
    ? price
    : sailPriceError;
};

export const convertQuotesByTariffToTariffPrice = (
  quotesByTariff: QuotesByTariff,
  sailingCode: string
): TariffPrice => {
  const { SPECIAL, STANDARD } = quotesByTariff;

  const specialSailings = SPECIAL?.sailings.find((s) => s.sailingCode === sailingCode);
  const specialChargeInfo = specialSailings?.leg?.chargeInfo || null;
  const standardSailings = STANDARD?.sailings.find((s) => s.sailingCode === sailingCode);
  const standardChargeInfo = standardSailings?.leg?.chargeInfo || null;

  logger.info('convertQuotesByTariffToTariffPrice, specialChargeInfo', specialChargeInfo);
  logger.info('convertQuotesByTariffToTariffPrice, standardChargeInfo', standardChargeInfo);

  const tariffPrice: TariffPrice = {
    SPECIAL: specialChargeInfo && Number.isFinite(specialChargeInfo.charge) ? specialChargeInfo : PriceError.NO_SPECIAL,
    STANDARD:
      standardChargeInfo && Number.isFinite(standardChargeInfo.charge) ? standardChargeInfo : PriceError.NO_STANDARD,
  };
  return tariffPrice;
};

export const createInitialSailingChargeInfo = (sailing: Timetable): TariffPrice => {
  let priceError: PriceError;

  switch (sailing.availabilityInfo) {
    case ErrorCode.NO_AVAIL_PET_CABINS: {
      priceError = PriceError.NO_AVAIL_PET_CABINS;
      break;
    }
    case ErrorCode.ACCOMMODATION_NOT_AVAILABLE: {
      priceError = PriceError.ACCOMMODATION_NOT_AVAILABLE;
      break;
    }
    case ErrorCode.PRODUCT_NOT_FOUND:
      const match = /^The passenger product (\w+) is not available on sailing/.exec(sailing.availabilityReason);
      priceError = match ? PriceError.NO_PASSENGER_CAPACITY : PriceError.NO_PRICE;
      break;
    case VehicleError.NO_VEHICLE_SPACE: {
      priceError = PriceError.NO_VEHICLE_SPACE;
      break;
    }
    default:
      priceError = PriceError.NO_PRICE;
  }

  const tariffPrice: TariffPrice = {
    SPECIAL: sailing.chargeTotal
      ? {
          __typename: 'ChargeInfo',
          charge: sailing.chargeTotal,
          discount: 0,
          discountDesc: null,
          discountStarClub: null,
          tax: 0,
        }
      : priceError,
    STANDARD: PriceError.NO_STANDARD,
  };
  logger.debug(
    'InitialSailingChargeInfo:',
    sailing.sailingCode,
    'chargeTotal:',
    sailing.chargeTotal,
    'tariffPrice:',
    tariffPrice
  );
  return tariffPrice;
};

export const timetableVehicleError = (sailing: Timetable): VehicleError | undefined => {
  return sailing.availabilityInfo === VehicleError.NO_VEHICLE_SPACE ? VehicleError.NO_VEHICLE_SPACE : undefined;
};

export const accommodationsMatch = (
  first: ExtendedAccommodation[] | AccommodationError | undefined,
  second: ExtendedAccommodation[],
  sailingCode: string
): boolean => {
  if (!first || !Array.isArray(first)) {
    return false;
  }
  // Compare accommodation codes (empty arrays also matches)
  const firstCodes: string[] = first.map((item: ExtendedAccommodation) => item.code);
  const secondCodes: string[] = second.map((item: ExtendedAccommodation) => item.code);
  const match: boolean = firstCodes.every((code: string) => secondCodes.includes(code));
  if (!match) logger.warn(`Accommodations mismatch for ${sailingCode}, [${firstCodes}] -> [${secondCodes}]`);
  return match;
};

// Method for selecting either existing accommodations (probably modified by user) or received accommodations (while in loading phase).
// Returns boolean, where true = select first (existing) one or false select second (received) one.
export const chooseByAccommodations = (
  first: ExtendedAccommodation[] | AccommodationError | undefined,
  second: ExtendedAccommodation[] | AccommodationError | undefined
): boolean => {
  if (!first || !Array.isArray(first)) {
    return false;
  }

  if (!second || !Array.isArray(second)) {
    return true;
  }

  const firstCodes: string[] = first.map((item: ExtendedAccommodation) => item.code);
  const secondCodes: string[] = second.map((item: ExtendedAccommodation) => item.code);
  const same: boolean = firstCodes.every((code: string) => secondCodes.includes(code));

  // Check if user has done any modifications or adding new accommodation
  return firstCodes.length > secondCodes.length || !same;
};

export const hasEnoughPassengerCapacity = (
  products: Products | ProductError,
  passengersAndPets: ExtendedPassengerAndPet[]
): boolean => {
  if (typeof products === 'string') {
    return false;
  }
  const capacity = products.PASSENGER?.find((p) => p.code === 'A')?.capacity;

  const required = passengersAndPets.filter(isPassengerInput).length;
  return capacity?.available ? capacity.available >= required : false;
};
